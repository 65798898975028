<!-- START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
<template>
    <div :class="getClassList" ref="planWrapper">
        <div @click="handleCardClick" class="plan-box -multi-sub" ref="plan-box">
            <!-- main plan box -->
            <div class="plan-box__wrapper">
                <div v-if="isBestValue" class="plan-box__pill -current">
                    {{ getPillText }}
                </div>

                <div class="plan-box__title" ref="title">
                    <h3>{{ plan.planTitle }}</h3>
                    <span class="plan-box__radio">
                        <span class="plan-box__outline"></span>
                    </span>
                </div>

                <div v-if="getTrialCopy" class="plan-box__copy">
                    {{ getTrialCopy }}
                </div>

                <div class="plan-box__price plan-price" ref="price">
                    <div class="plan-price__price">${{ plan.rawPrice }}</div>
                    <div v-if="getTrialCopy" class="plan-price__trial">
                        {{ planPriceCopy }} {{ monthlyEffectivePriceCopy }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import pickAPlanBox from 'aa/vue/components/PickAPlanBox';
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'AbTestIntlLikeMultiSubBox',
        extends: pickAPlanBox,
        mixins: [AbTestPlanTilesAboveFoldMixin],
        data: function () {
            return {
                coupon: '',
            };
        },
        props: {
            idx: {
                type: Number,
            },
            plan: {
                type: Object,
            },
            isActive: {
                type: Boolean,
            },
            selectionMade: {
                type: Boolean,
            },
        },
        computed: {
            ...mapGetters(['featureIsActive']),
            isBestValue() {
                return this.plan.tier === 2;
            },
            getPillText() {
                return this.isBestValue ? 'BEST VALUE' : '';
            },
            planPriceCopy() {
                const prefix = this.plan.planType === Product.TYPE_MONTHLY ? '/mo' : '/yr';

                let priceCopy = this.pageAttrs.aa_pap_plan_price_format_with_trial;
                let dispPrice = priceCopy.replace('%PRICE_WITH_UNIT%', prefix);
                let dot = this.monthlyEffectivePriceCopy ? '. ' : '';
                return dispPrice + dot;
            },
            monthlyEffectivePriceCopy() {
                if (!this.isAnnualPlan) {
                    return null;
                }

                try {
                    let monthlyEffectivePriceCopy =
                        this.pageAttrs.aa_pap_annual_plan_monthly_price_format.replace(
                            '%MONTHLY_PRICE_WITH_UNIT%',
                            this.plan.annualPlanMonthlyPriceWithUnit(),
                        );

                    return monthlyEffectivePriceCopy.replace('(', '').replace(')', '');
                } catch (e) {
                    return null;
                }
            },
            getClassList() {
                const classList = ['plan'];
                if (this.isActive) {
                    classList.push('-active');
                }
                return classList;
            },
        },
        mounted() {
            // notify parent to display default features list
            this.notifyParent();

            // set margin left for first plan box on load
            this.centerAlignPlans();

            // listener for re-centering plans on orientation change (if no plan is selected)
            window.addEventListener('orientationchange', () => {
                this.orientationChanged().then(() => {
                    this.centerAlignPlans();
                });
            });

            // listener for re-centering plans on resize (if no plan is selected)
            window.addEventListener('resize', () => {
                this.centerAlignPlans();
            });
        },
        methods: {
            orientationChanged() {
                const timeout = 120;
                return new window.Promise(function (resolve) {
                    const go = (i, height0) => {
                        window.innerHeight !== height0 || i >= timeout
                            ? resolve()
                            : window.requestAnimationFrame(() => go(i + 1, height0));
                    };
                    go(0, window.innerHeight);
                });
            },
            centerAlignPlans() {
                if (!this.selectionMade && this.idx === 0) {
                    const el = document.querySelector('.v-slide-group__content');
                    el.style.transform = '';

                    const width = this.$refs['plan-box'].clientWidth;
                    const padding = 2; // each plan box
                    const border = 4; // 2 each side
                    const gutter = 40; // 20 on each side
                    const marginLeft = (window.innerWidth - width - border - padding) / 2 - 2;

                    document.querySelector('.plan').style.marginLeft = marginLeft + 'px';
                }
            },
            notifyParent() {
                if (
                    (this.highlightedPlanIdx === -1 && this.idx === 0) ||
                    this.highlightedPlanIdx === this.idx
                ) {
                    this.$emit('setFeaturesList', this.featureList);
                }
            },
            handleCardClick: function (e) {
                e.stopPropagation();
                this.$store.dispatch('variant/setHighlightedPlanIdx', this.idx);
                this.$emit('planSelected', {
                    idx: this.idx,
                    selectionMade: true,
                });
            },
        },
        watch: {
            featureList: {
                handler: function () {
                    this.notifyParent();
                },
                deep: true,
            },
            highlightedPlanIdx: function () {
                this.notifyParent();
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
