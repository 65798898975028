import Domain from 'helpers/Domain';
import { RENDEZVOUS } from 'helpers/featureConstants';
import SignInComponent from 'aa/vue/components/SignIn';
import Step from 'models/steps/Step';

export default class SignIn extends Step {
    path = '/account/signin/';
    name = 'SIGNIN';
    component = SignInComponent;
    requireAuth = false;
    requireNonAuth = true;
    allowGhost = true;
    hideSignInLink = true;
    props = true;

    setProps(props) {
        this.props = (route) => ({
            ...props,
            ...route.params,
        });
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageTitle = 'Login to Stream Live TV, Sports, News and On Demand with Paramount+';
        args.pageType = 'svod_signin';

        if (!Domain.isDomestic()) {
            if (this._store.getters['flow/userLoginType']) {
                args.userLoginType = this._store.getters['flow/isRendezvous']
                    ? RENDEZVOUS
                    : this._store.getters['flow/userLoginType'];
            }
        }

        if (this._store.state?.partner?.subscriptionDetails?.partnerCode) {
            args.activationShortCode = this._store.state.partner.subscriptionDetails.partnerCode;
        }

        return args;
    }
}
