<template>
    <div>
        <BillingPlanSummary
            :internationalizedPriceWithUnit="internationalizedPriceWithUnit"
            :countryClass="countryClass"
            :pageAttr="pageAttr"
            :isFromDirect="isFromDirect"></BillingPlanSummary>
        <BillingPromoSummary
            v-if="showPromoComponent"
            :formConfig="formConfig"
            :displayTrialInfo="displayTrialInfo"
            :orderSummaryConfig="orderSummaryConfig"
            :form="form"
            :coupon="coupon"
            :keydownHandler="handleEnterKey"
            :buttonConfig="buttonConfig"
            :plan="plan"
            :handleClickCouponCTA="handleClickCouponCTA"
            :countryClass="countryClass"
            :internationalizedPrice="internationalizedPrice"
            :pricing="pricing"
            :showSubTotalNow="showSubTotalNow"
            :pageAttr="pageAttr"></BillingPromoSummary>
        <BillingPaymentSummary
            :countryClass="countryClass"
            :disclaimerCopyEl="disclaimerCopyEl"
            :displayTrialInfo="displayTrialInfo"
            :nextBillingDate="getNextEndDate"
            :pageAttr="pageAttr"
            :discountString="coupon && coupon.discountString"
            :nextNonZeroBillingPrice="nextNonZeroBillingPrice"></BillingPaymentSummary>
        <input
            v-if="plan && plan.recurlyCode"
            type="hidden"
            data-recurly="plan"
            :value="plan.recurlyCode" />
        <input ref="couponRecurly" value="" type="hidden" data-recurly="coupon" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import InputElm from 'atoms/InputElm';
    import InputField from 'atoms/InputField';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import ButtonCta from 'atoms/ButtonCta';
    import { OrderSummaryConfig } from 'aa/vue/FormConfig';
    import WaitingOverlay from 'aa/vue/atoms/WaitingOverlay';
    import { DateFormatter, getEndDate } from 'aa/helpers/DateFormatter';
    import Domain from 'helpers/Domain';
    import BillingPlanSummary from 'aa/vue/components/BillingPlanSummary';
    import BillingPromoSummary from 'aa/vue/components/BillingPromoSummary';
    import BillingPaymentSummary from 'aa/vue/components/BillingPaymentSummary';

    export default {
        components: {
            InputElm,
            InputField,
            ButtonCta,
            WaitingOverlay,
            BillingPlanSummary,
            BillingPromoSummary,
            BillingPaymentSummary,
        },
        props: {
            coupon: {
                type: Object,
            },
            couponDisclaimer: {
                type: String,
            },
            couponCheckPending: {
                type: Boolean,
            },
            recurly: {
                type: Object,
            },
            annualPlanOption: {
                type: Boolean,
                default: true,
            },
            formConfig: {
                type: Object,
                default: null,
            },
            nextNonZeroBillingPrice: {
                type: String,
            },
            pricing: {
                type: Object,
            },
            pageAttr: {
                type: Object,
            },
            couponDiscountBillingDate: {
                type: String,
                default: null,
            },
            isFromDirect: {
                type: Boolean,
                default: false,
            },
        },

        mixins: [pricePrettyPrint],
        data: function () {
            return {
                isPaymentPage: true,
                form: {},
                countryClass: '',
                internationalizedPrice: null,
                internationalizedZeroPrice: null,
                internationalizedPriceWithUnit: null,
                orderSummaryConfigDefault: OrderSummaryConfig,
            };
        },

        computed: {
            orderSummaryConfig() {
                return this.formConfig || this.orderSummaryConfigDefault;
            },

            ...mapGetters('user', ['isNoTrialUser', 'isSubscriber']),

            ...mapGetters(['bundleShowtime']),

            ...mapGetters('plan', ['getSelectedPlan']),

            displayTrialInfo() {
                if (this.removeTrial) {
                    return false;
                }
                return this.coupon?.trialString || this.plan?.trialString;
            },

            removeTrial() {
                // ex-subs without trial extension or coupon not eligible for free trial
                return (
                    Domain.isDomestic() &&
                    ((this.isNoTrialUser && !this.coupon.trialString) || !this.plan?.trial)
                );
            },

            isInternational() {
                return Domain.isInternational();
            },

            isNoTrialUserWithCouponTrial() {
                return this.isNoTrialUser && !!this.coupon.trialString;
            },

            isNoTrialUserWithoutCouponTrial() {
                return this.isNoTrialUser && !this.coupon.trialString;
            },

            hasPromoCode() {
                return this.$store.state.promo == null ? false : true;
            },

            promoSuppressedByShowtimeBundleCopy() {
                if (this.pageAttr.aa_promo_suppressed_message_showtime === undefined) {
                    return '';
                } else {
                    return this.pageAttr.aa_promo_suppressed_message_showtime.replace(
                        '%COUPON_CODE%',
                        this.$store.state.promo,
                    );
                }
            },

            plan() {
                if (!this.getSelectedPlan) {
                    return null;
                }
                let currentPlan = this.getSelectedPlan;

                if (currentPlan.trialString && this.isNoTrialUser) {
                    currentPlan.trialString = null;
                }

                return currentPlan;
            },

            couponTrialEndDate() {
                let discount = !this.coupon.recurlyInfo ? null : this.coupon.recurlyInfo.discount;

                if (!discount || discount.type !== 'free_trial') {
                    return null;
                }

                try {
                    let { amount, unit } = discount.trial;
                    let trialEndDate = getEndDate(amount, unit);
                    return DateFormatter.format(trialEndDate, null, CBS.Registry.region.dateLocale);
                } catch (e) {
                    return null;
                }
            },

            nextBillingDate() {
                const nextBillingDate = this.couponTrialEndDate || this.plan?.next_billing_date;
                this.$emit('trialEndDateFormatted', nextBillingDate);
                return nextBillingDate;
            },

            getNextEndDate() {
                // based on 100% coupon off
                return this.couponDiscountBillingDate || this.nextBillingDate;
            },

            disclaimerCopyEl() {
                let trialEndEl = `<span id="trial-end-date" data-original="${this.plan?.trialEndDate}">
                    ${this.plan?.trialEndDate}</span>`;
                let billingSummaryCopy = this.isSubscriber
                    ? this.pageAttr.billing_summary_trial_end_copy_subs
                    : this.pageAttr.billing_summary_trial_end_copy;

                return (billingSummaryCopy || '').replace('%d', trialEndEl).trim();
            },

            buttonConfig() {
                return Object.assign(
                    {
                        displayText:
                            this?.pageAttr?.billing_summary_coupon_field_cta ??
                            this.$getLocale('ok'),
                        event: this.handleClickCouponCTA,
                    },
                    OrderSummaryConfig.buttonConfig,
                );
            },

            showSubTotalNow() {
                return !this.removeTrial || this.isInternational;
            },

            showSubTotalNext() {
                return this.removeTrial && !this.isNoTrialUserWithCouponTrial;
            },

            showIntlZeroPrice() {
                return this.removeTrial && this.isNoTrialUserWithCouponTrial;
            },

            showPromoComponent() {
                // Always show during Sign Up flow and Direct Link flow
                if (this.$route.name === 'PAYMENT_SUMMARY' || this.$route.name === 'DIRECTLINK') {
                    return true;
                }

                // For switch plan flow, only show if minimum term enabled
                return this.pageAttr?.billing_summary_minimum_term_enabled;
            },
        },

        methods: {
            getInternationalizedPricesUsingRecurly() {
                if (this.recurly && this.getSelectedPlan) {
                    const currentPlan = this.getSelectedPlan;
                    const { currencySymbol, currencyCode } = this.recurly.Pricing.Checkout();
                    let currentPlanPrice = parseFloat(currentPlan.rawPrice.replace(/,/g, ''));
                    this.internationalizedZeroPrice = `${this.pricePrettyPrint(0, currencySymbol, currencyCode)}`;
                    this.internationalizedPrice = `${this.pricePrettyPrint(
                        currentPlanPrice,
                        currencySymbol,
                        currencyCode,
                    )}`;

                    this.internationalizedPriceWithUnit = `${this.pricePrettyPrint(
                        currentPlanPrice,
                        currencySymbol,
                        currencyCode,
                    )} / ${currentPlan.priceUnit}`;

                    this.$emit('found-price', {
                        internationalizedPrice: this.internationalizedPrice,
                        internationalizedPriceWithUnit: this.internationalizedPriceWithUnit,
                    });
                }
            },

            handleClickCouponCTA(e) {
                e.preventDefault();

                const couponCode = this.attachCurrentCouponToForm();
                if (this.form.couponCode?.value !== null && this.form.couponCode?.value !== '') {
                    this.$refs.couponRecurly.value = couponCode;
                } else {
                    //resetting coupon error message and css class
                    this.resetCouponFieldErrorMessage();
                }

                if (this.plan) {
                    this.$emit('applyCoupon', {
                        plan: this.plan.recurlyCode,
                        couponCode: couponCode,
                    });
                }

                this.getInternationalizedPricesUsingRecurly();
            },

            handleEnterKey(e) {
                if (e.key === 'Enter' || e.code === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();

                    this.handleClickCouponCTA(e);
                }
            },

            attachCurrentCouponToForm(couponCodeOverride) {
                let couponCode = couponCodeOverride
                    ? couponCodeOverride
                    : this.form.couponCode?.value || '';

                if (this.$refs.couponRecurly) {
                    this.$refs.couponRecurly.value = couponCode;
                }

                return couponCode;
            },

            resetCouponFieldErrorMessage() {
                document.querySelector('.field-wrapper--coupon-code').classList.remove('error');
                this.coupon.errorMessage = '';
                this.$emit('resetCoupon');
            },
        },

        // Lifecycle Hooks
        beforeCreate: function () {
            Object.assign(OrderSummaryConfig.hiddenInputList.plan, {
                value: this.$store.getters['plan/getSelectedPlan']?.recurlyCode,
            });
        },

        created() {
            this.registrationCountry = this.$store.state.serverData.paymentForm?.country?.value
                ? this.$store.state.serverData.paymentForm?.country?.value.toLowerCase()
                : false;
            this.countryClass = `payment-summary-${this.registrationCountry}`;

            if (this.recurly) {
                this.getInternationalizedPricesUsingRecurly();
            }
        },

        watch: {
            recurly(newVal, oldVal) {
                if (oldVal === null && newVal !== null) {
                    this.getInternationalizedPricesUsingRecurly();
                }
            },
            plan(newVal) {
                const couponCode = this.attachCurrentCouponToForm();
                if (this.plan) {
                    this.$emit('updateOrder', {
                        plan: this.plan.recurlyCode,
                        couponCode: couponCode,
                    });
                }
                this.getInternationalizedPricesUsingRecurly();
            },
            autoPromoChecked(newVal) {
                if (newVal === true) {
                    this.$nextTick(() => {
                        this.attachCurrentCouponToForm(
                            this.orderSummaryConfig?.inputList?.couponCode?.value,
                        );
                    });
                }
            },
        },
    };
</script>
