<template>
    <div :class="getClassList">
        <div class="grid-item-interior">
            <div v-if="showBestValue" class="best-value">
                <span>{{ getBestPillCopy }}</span>
            </div>
            <div class="plan-name">{{ plan.planTitle }}</div>
            <div v-if="hasCoupon" class="plan-discount-percentage">
                {{ couponString }}
            </div>
        </div>
    </div>
</template>
<script>
    import PickAPlanBox from 'aa/vue/components/PickAPlanBox';
    import { mapGetters, mapState } from 'vuex';
    import { getLocale } from 'services/LocalizationService';

    export default {
        name: 'UpsellPlansHeaderItem',
        extends: PickAPlanBox,
        data: function () {
            return {};
        },
        props: {
            plan: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            hasCoupon() {
                let isValid = this.plan.hasValidCoupon();
                if (isValid) {
                    this.$emit('hasCoupon', isValid);
                }
                return isValid;
            },
            showBestValue() {
                return this.plan.isCF;
            },
            getBestPillCopy() {
                return this.pageAttributes?.plan_pill_copy ?? '';
            },
            getClassList() {
                return {
                    'grid-item': true,
                    hasAnnual: this.upsellPlansAnnualEnabled,
                };
            },
            couponString() {
                try {
                    if (this.plan.hasForeverCoupon()) {
                        return this.foreverFormat.replace(
                            '{percent}',
                            this.plan.getCouponAmountFormatted(),
                        );
                    }
                    return this.getCouponString();
                } catch (e) {
                    return '';
                }
            },
            foreverFormat() {
                return getLocale('upsell_percent_discount_forever', {}, '{percent} off');
            },
        },
    };
</script>
