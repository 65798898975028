<template>
    <ul class="header__account header__nav--items">
        <UserDropDown v-if="isLoggedIn" :aaRegion="aaRegion"></UserDropDown>
        <li v-else-if="displaySignUpLink">
            <a
                class="qt-signup"
                tabindex="0"
                role="button"
                :aa-link="[aaRegion, '', signUpCTACopy.toLowerCase()] | aaLinkFilter"
                @click.stop.prevent="onClickSignUp">
                {{ $getLocale('sign_up') }}
            </a>
        </li>

        <li v-else-if="displaySignInLink">
            <router-link
                :to="signInPath"
                tabindex="0"
                class="qt-signin"
                role="button"
                :aa-link="[aaRegion, '', 'sign in'] | aaLinkFilter"
                @click.stop.prevent="onClickSignIn">
                {{ $getLocale('sign_in') }}
            </router-link>
        </li>
    </ul>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import UserDropDown from './UserDropDown.vue';

    import Domain from 'helpers/Domain';
    import {
        ACCOUNT_BASE_PATH,
        RENDEZVOUS_BASE_PATH,
        SIGNUP_URL,
        SIGNIN_URL,
        TVE_RENDEZVOUS_BASE_PATH,
    } from 'aa/vue/constants/aaRoutes';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';
    import { AA_PURCHASE } from 'helpers/featureConstants';
    import { FLOW_TYPE } from 'models/FlowModel';

    export default {
        name: 'UserEntry',
        components: {
            UserDropDown,
        },
        props: {
            aaRegion: {
                type: String,
            },
            accountFeaturesDisabled: {
                type: Boolean,
                default: false,
            },
            showSignIn: {
                type: Boolean,
                default: true,
            },
        },
        data: function () {
            return {
                currentRouteName: this.$router.currentRoute.name,
            };
        },
        computed: {
            hasSkipExplainers() {
                return this.featureIsActive(SKIP_EXPLAINER_STEPS);
            },
            isPurchaseFunnelRoute() {
                return (
                    this.$router.options.base ===
                    this.$root.serverData.regionBaseUrl + ACCOUNT_BASE_PATH
                );
            },
            isRendezvousRoute() {
                return (
                    this.$router.options.base ===
                    this.$root.serverData.regionBaseUrl + RENDEZVOUS_BASE_PATH
                );
            },
            isSignInPage() {
                return this.currentRouteName === 'SIGNIN';
            },
            isTVERendezvous() {
                return this.currentRouteName === TVE_RENDEZVOUS_BASE_PATH;
            },
            displaySignInLink() {
                if (Domain.isDomestic()) {
                    return (
                        !this.isLoggedIn &&
                        !this.isSignInPage &&
                        !Boolean(this.$route?.meta?.hideSignInLink)
                    );
                } else {
                    return (
                        !this.isLoggedIn &&
                        !this.isSignInPage &&
                        this.currentRouteName !== 'EDU' &&
                        !this.isTVERendezvous &&
                        this.showSignIn
                    );
                }
            },
            displaySignUpLink() {
                if (Domain.isDomestic()) {
                    return this.isSignInPage && !Boolean(this.$route?.meta?.hideSignUpLink);
                } else {
                    return (
                        this.isSignInPage &&
                        !this.isTVERendezvous &&
                        !this.$root.serverData.isRegionOnSunset
                    );
                }
            },
            signInPath() {
                let { regionBaseUrl } = this.$root.serverData;
                if (Domain.isDomestic() && this.isRendezvousRoute) {
                    return this.$store.getters['flow/getRedirectUrlRendezvous'].signIn;
                } else if (
                    this.$route.query.redirectUrl &&
                    this.$route.query.redirectUrl.includes('/account/direct/')
                ) {
                    return `${regionBaseUrl}${SIGNIN_URL}/?redirectUrl=${encodeURIComponent(this.$route.query.redirectUrl)}`;
                } else if (Domain.isInternational() && this.isRendezvousRoute) {
                    return {
                        path: `${SIGNIN_URL}/`,
                    };
                } else if (this.isReseller) {
                    return `/signin/${window.location.search}`;
                }

                return `${regionBaseUrl}${SIGNIN_URL}/`;
            },
            signUpCTACopy() {
                return CBS.Registry.brand.name == 'PPLUS' ? 'Sign Up' : 'Create Account';
            },

            ...mapState({
                isMVPDEventBound: (state) => state['authSuite']?.mvpdLoginEventBound,
            }),
            ...mapState('user', {
                isLoggedIn: (state) => state.isLoggedIn,
            }),

            ...mapGetters(['featureIsActive']),
            ...mapGetters('flow', {
                flowType: 'type',
                isSignInFlow: 'isSignInFlow',
                isReseller: 'isReseller',
            }),
        },
        watch: {
            $route(to, from) {
                this.currentRouteName = to.name;
            },
        },
        filters: {
            aaLinkFilter: function (val) {
                return tracking.aaLinkFilter(val);
            },
        },
        methods: {
            onClickSignUp(e) {
                let { regionBaseUrl } = this.$root.serverData;
                tracking.trackAction(ACTION_NAMES.SIGN_UP_NON_NATIVE_RENDEZVOUS, {
                    ctaText: this.signUpCTACopy,
                    menuItemLabel: 'sign up',
                    menuOrientation: 'header',
                    posRowNum: '0',
                    posColNum: '1',
                });

                if (this.isSignInFlow) {
                    const featurePurchase = this.featureIsActive(AA_PURCHASE);
                    if (featurePurchase) {
                        window.location.assign(
                            `${regionBaseUrl + ACCOUNT_BASE_PATH}user-flow/f-upsell/`,
                        );
                    } else if (
                        this.$route.query.redirectUrl &&
                        this.$route.query.redirectUrl.includes('/account/direct/')
                    ) {
                        this.$router.push({
                            name: 'SIGNUP',
                            params: {
                                redirectUrl: this.$route.query.redirectUrl,
                            },
                        });
                    } else if (this.hasSkipExplainers) {
                        this.$router.push({
                            name: 'SIGNUP',
                        });
                    } else {
                        this.$router.push({
                            name: 'sign_up_explainer',
                        });
                    }
                } else if (this.isPurchaseFunnelRoute) {
                    this.$router.push({
                        name: 'SIGNUP',
                    });
                } else if (this.isReseller) {
                    this.$router.push({
                        name: 'SIGNUP',
                        query: this.$route.query,
                    });
                } else if (this.$router?.currentRoute?.params?.fromPartnerConfirmation) {
                    // Maintain partner flow - PPIWEB-3279
                    this.$router.push({
                        name: 'SIGNUP',
                        params: { fromPartnerConfirmation: true },
                    });
                } else if (
                    this.$route.query.redirectUrl &&
                    this.$route.query.redirectUrl.includes('/account/direct/')
                ) {
                    this.$router.push({
                        name: 'SIGNUP',
                        query: { redirectUrl: this.$route.query.redirectUrl },
                    });
                } else {
                    // from rendezvous app
                    window.location.assign(`${regionBaseUrl}${SIGNUP_URL}`);
                }
            },
            onClickSignIn() {
                this.trackSignIn();
                if (this.$router?.currentRoute?.params?.fromPartnerConfirmation) {
                    // Maintain partner flow - PPIWEB-3279
                    this.$router.push({
                        name: 'SIGNIN',
                        params: { fromPartnerConfirmation: true },
                    });
                } else if (this.isMVPDEventBound) {
                    this.$router.push({
                        name: 'SIGNIN',
                        params: { fromConfirmation: true },
                    });
                } else if (
                    this.$route.query.redirectUrl &&
                    this.$route.query.redirectUrl.includes('/account/direct/')
                ) {
                    this.$router.push({
                        name: 'SIGNIN',
                        params: { redirectUrl: encodeURIComponent(this.$route.query.redirectUrl) },
                    });
                } else {
                    // we just cleaned the code from https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
                    const query = this.isReseller
                        ? Object.fromEntries(
                              window.location.search
                                  .slice(1)
                                  .split('&')
                                  .map((kv) => kv.split('=').map(decodeURIComponent)),
                          )
                        : {};

                    this.$router.push({ name: 'SIGNIN', query });
                }
            },
            trackSignIn() {
                if (Domain.isInternational() && this.flowType === FLOW_TYPE.GIFT) {
                    tracking.trackAction(ACTION_NAMES.LOGIN, {
                        pageType: 'svod_signin',
                        userLoginType: 'gift',
                        ctaText: this.$getLocale('sign_in'),
                    });

                    return;
                }

                tracking.trackAction(ACTION_NAMES.LOGIN, {
                    ctaText: 'Sign In',
                    menuItemLabel: 'sign in',
                    menuOrientation: 'header',
                    posRowNum: '0',
                    posColNum: '1',
                });
            },
        },
    };
</script>
