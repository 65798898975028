import { catanFlowType } from 'aa/vue/constants/aaBundle';
import Domain from 'helpers/Domain';
import { default as FlowModel, FLOW_TYPE } from 'models/FlowModel';
import { USER_LOGIN_TYPE } from 'services/Tracking';
import {
    WALMART_PLUS_CONSENT,
    WEB_RENDEZVOUS_SINGLE_URL_ENABLED,
} from 'aa/helpers/featureConstants';
import PartnerService from 'services/PartnerService';
import { USER_REG_TYPE, userRegTypesForFlow } from 'aa/vue/constants/aaTrackingConstants';
import HttpService from 'services/HttpService';

const DEFAULT_CAMPAIGN_ID = 'pplus';

export default {
    namespaced: true,

    state: {
        config: new FlowModel(),
        steps: [],
        currentStep: null,
        stepsCount: null,
        didCompleteSignIn: false,
        skipNextBundleStep: false,
        paymentComplete: false,
        userConsent: false,

        marquee: {
            messageHeading: '',
            messageBody: '',
        },
    },

    getters: {
        code: ({ config }) => {
            return config.code;
        },

        type: ({ config }) => {
            return config.type;
        },

        getCampaignId(state) {
            return state.config?.campaignID || DEFAULT_CAMPAIGN_ID;
        },

        getCampaignPageAttributeTag(state, getters) {
            return `campaign_${getters.getCampaignId}`;
        },

        getTrackingActivationInfo(state, getters) {
            return {
                activationShortCode: state.config.code || '',
                ottActivationPartner: getters.ottActivationPartner || '',
                ...(getters.isRendezvous
                    ? { activationDeviceFamily: state.config?.activationDeviceFamily || '' }
                    : {}),
            };
        },

        hasDefaultCampaign(state, getters) {
            return getters.getCampaignId === DEFAULT_CAMPAIGN_ID;
        },

        ottActivationPartner: ({ config }, getters, rootState) => {
            if (
                rootState.partner &&
                PartnerService.isPartnerFlow(rootState.partner.partnerCSPValue)
            ) {
                return rootState.partner.partnerAuthAttributes.csp_display_name;
            }
            return config.activationPartner;
        },

        origin_url: ({ config }) => {
            const regexIntlPath = /\/intl/i;
            const originPath = config.origin_url || '';
            const url = new URL(originPath, window.location.origin);
            return regexIntlPath.test(url.pathname) ? url.search : originPath;
        },

        isPartnerAuth: ({ config }, getters, rootState) => {
            if (PartnerService.isPartnerFlow(rootState.partner.partnerCSPValue)) {
                return true;
            }
            return config.isPartnerAuth;
        },

        displaySteps: ({ config }) => {
            return config.steps || false;
        },

        afterSignUpUrl: ({ config }) => {
            return config.afterSignUpUrl || config.destinationUrl;
        },

        destinationUrl: ({ config }, getters) => {
            if (Domain.isDomestic() && getters.campaignDestinationURL) {
                return getters.campaignDestinationURL;
            }
            return config.destinationUrl;
        },

        campaignDestinationURL: (state, getters, rootState) => {
            if (getters.isCampaign) {
                return rootState.pageAttributes?.attributes?.destination_url;
            }

            return null;
        },

        isSignInFlow: ({ config }) => {
            return config.type === FLOW_TYPE.SIGNIN;
        },

        isGift: (state, getters) => {
            return getters.type === FLOW_TYPE.GIFT;
        },

        isEdu: ({ config }) => {
            return config.type === FLOW_TYPE.EDU;
        },

        isRendezvous: ({ config }) => {
            return config.rendezvous && typeof config.rendezvous === 'object';
        },
        isReseller: ({ config }) => {
            return FlowModel.isReseller(config.type);
        },

        isNative: ({ config }, getters) => {
            if (!getters.isRendezvous) {
                return false;
            }

            return config.rendezvous.isNative;
        },

        isCampaign: ({ config }) => {
            return config?.type?.indexOf('campaign_') === 0;
        },

        isNoFreeTrialCampaign: (state, getters, rootState) => {
            return !!rootState.pageAttributes.attributes.no_free_trial;
        },

        isCatan: ({ config }) => {
            return config.type === catanFlowType();
        },

        /**
         * @param state
         * @param getters
         * @returns {unknown | default.getters.userRegType | userRegType | string}
         */
        userLoginType: (state, getters) => {
            if (getters.isPartnerAuth) {
                return USER_LOGIN_TYPE.PARTNER_AUTH;
            }

            return USER_LOGIN_TYPE[getters.type] || getters.userRegType;
        },

        userRegType: (state, getters) => {
            if (getters.isPartnerAuth) {
                return USER_LOGIN_TYPE.PARTNER_AUTH;
            } else if (getters.isRendezvous) {
                return USER_REG_TYPE.RENDEZVOUS;
            } else if (state.config.type && userRegTypesForFlow[state.config.type]) {
                return userRegTypesForFlow[state.config.type];
            } else if (state.config.reg_driver) {
                return state.config.reg_driver;
            }

            return USER_REG_TYPE.STANDARD;
        },

        // add cases as needed
        skipPlan: (state, getters) => {
            return getters.type === FLOW_TYPE.EDU || getters.isNative;
        },

        // add cases as needed
        skipPayment: (state, getters) => {
            return (
                getters.isNative ||
                getters.type === FLOW_TYPE.GIFT ||
                CBS.Registry.brand.name !== 'PPLUS'
            );
        },

        getFirstStepForAuth(state) {
            return state.steps.find((step) => {
                return !step.requireNonAuth;
            });
        },

        getFirstStepForNonAuth(state) {
            return state.steps.find((step) => {
                return !step.requireAuth;
            });
        },

        getNextStep({ steps, currentStep }, getters, rootState, rootGetters) {
            let nextStep = null;
            let isAuth = rootState.user.isLoggedIn;

            for (let i = currentStep + 1; i < steps.length; i++) {
                let step = steps[i];

                if (
                    isAuth &&
                    step.requireNonAuth && // skip sign in/up steps
                    !step.isGhostAndAllowed()
                ) {
                    // do not skip if ghost user is allowed to sign in/up as next step
                    continue;
                }

                if (!isAuth && step.requireAuth) {
                    continue;
                }

                nextStep = step;
                break;
            }

            return nextStep;
        },

        getCurrentStep({ steps, currentStep }) {
            return currentStep && steps[currentStep] ? steps[currentStep] : steps[0];
        },

        paymentFlowComplete: (state) => {
            return state.paymentComplete;
        },

        countedSteps(state) {
            return state.steps.filter((step) => {
                return step.countStep;
            });
        },

        baseURL: ({ config }) => {
            return config.baseUrl;
        },
        isPartner(state, getters) {
            if (getters.type === 'tmobile' || getters.type === 'verizon') {
                return true;
            }
            return false;
        },

        /*User Data Share Acknowledgement*/
        isUserAcknowledgementEnabled: (state, getters) => {
            if (getters.isReseller || getters.isPartner) {
                return true;
            }
            return false;
        },
        isResellerWalmart(state, getters, rootState, rootGetters) {
            if (
                rootGetters['featureFlags/isActive'](WALMART_PLUS_CONSENT) &&
                getters.isReseller &&
                getters.type === FLOW_TYPE.WALMARTPLUS
            ) {
                return true;
            }
            return false;
        },

        isSingleUrlEnabledAgnosticFlow(state, getters, rootState, rootGetters) {
            const isDomestic = Domain.isDomestic();
            const isFeatureFlagActive = rootGetters['featureFlags/isActive'](
                WEB_RENDEZVOUS_SINGLE_URL_ENABLED,
            );
            const isConfigTypeAgnostic = state.config.type === FLOW_TYPE.AGNOSTIC_RENDEZVOUS;

            return isDomestic && isFeatureFlagActive && isConfigTypeAgnostic;
        },

        getRedirectUrlRendezvous(state, getters) {
            const baseUrl = getters.isSingleUrlEnabledAgnosticFlow ? 'tv' : getters.type;
            const isDomestic = Domain.isDomestic();

            return {
                signIn: isDomestic ? `/${baseUrl}/signin/` : '/signin/',
                complete: isDomestic ? `/${baseUrl}/complete/` : '/complete/',
                select: isDomestic ? `/${baseUrl}/select/` : '/select/',
            };
        },
    },

    mutations: {
        setDeviceInfo(state, { name, partner }) {
            state.config.activationDeviceFamily = partner;
            state.config.activationPartner = name;
        },
        setDeviceDisplayName(state, displayName) {
            state.config.rendezvous.displayName = displayName;
        },
        setConfig(state, config) {
            state.config = config;
        },
        setActivationCode(state, code) {
            state.config.code = code;
        },
        setSteps(state, steps) {
            state.steps = steps;
        },
        setCurrentStep(state, step) {
            state.currentStep = Math.min(step, state.steps.length - 1);
        },
        setMarquee(state, marquee) {
            state.marquee = marquee;
        },
        setStepsCount(state, stepsCount) {
            state.stepsCount = stepsCount;
        },
        decrementStepsCount(state) {
            if (state.stepsCount) {
                state.stepsCount--;
            }
        },
        setDidCompleteSignIn(state, didCompleteSignIn) {
            state.didCompleteSignIn = didCompleteSignIn;
        },
        setPaymentComplete(state) {
            state.paymentComplete = true;
        },
        setSkipNextBundleStep(state, skip) {
            state.skipNextBundleStep = skip;
        },
        setUserConsent(state, bool) {
            state.userConsent = bool;
        },
    },

    actions: {
        doConsent(context) {
            return new Promise((resolve, reject) => {
                let http = new HttpService();
                let payload = {
                    flowType: context.rootGetters['flow/type'],
                };
                http.doPost(`${context.rootGetters['flow/baseURL']}xhr/doConsent/`, payload).then(
                    (res) => {
                        if (res.success) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    },
                );
            });
        },
        loadStore({ commit }, flowConfig) {
            commit('setConfig', flowConfig);
        },
        skipNextBundleStep(context, skip) {
            context.commit('setSkipNextBundleStep', skip);
        },
        // decrements total step count
        didCompleteSignIn(context) {
            context.commit('setDidCompleteSignIn', true);
        },
        setCurrentStep(context, payload) {
            context.commit('setCurrentStep', payload.step);
        },
        setMarquee(context, payload) {
            context.commit('setMarquee', payload);
        },
        setSteps({ commit }, steps) {
            let stepIndex = 0;

            for (let config of steps) {
                config.stepIndex = stepIndex++; // array index of step, used in setCurrentStep
            }

            commit('setSteps', steps);
        },
        setActivationCode({ commit }, code) {
            commit('setActivationCode', code);
        },
        updateDeviceInfo({ state, commit }, deviceInfo = {}) {
            if (deviceInfo.code) {
                commit('setActivationCode', deviceInfo.code);
            }

            const _deviceInfo = {
                name: deviceInfo.name || state.config.activationPartner,
                partner: deviceInfo.partner || state.config.activationDeviceFamily,
            };

            if (state.config.type === FLOW_TYPE.AGNOSTIC_RENDEZVOUS && deviceInfo.name) {
                commit('setDisplayName', _deviceInfo.name);
            }

            commit('setDeviceInfo', _deviceInfo);
        },
    },
};
