import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
import { THREE_D_SECURE_ERROR_CODE } from 'aa/vue/constants/shared';
import { tracking, ACTION_NAMES, APP_LOG_TYPE } from 'services/Tracking';

export function displayFeedbackToUser(message, isSuccess) {
    this.openNotification(
        new NotificationBarModel({
            success: isSuccess,
            message: message,
        }),
    );
}

export function handleProcessedPayment() {
    localStorage.removeItem('selectedPlan');
    if (!this.$store.state.serverData.editPaymentRedirect || !this.isEditPayment) {
        this.openNotification(
            new NotificationBarModel({
                success: true,
                message: this.$getLocale('your_payment_information_was_successfully_updated'),
            }),
        );
    }
    const routerParams = {
        name: 'AccountMain',
    };
    if (this.$store.state.serverData.editPaymentRedirect && this.isEditPayment) {
        routerParams.query = { notification: 'update-payment' };
    }
    this.$router.push(routerParams);
}

export function handleProcessedPaymentError(data) {
    this.formIsProcessing = false;
    if (data.code === THREE_D_SECURE_ERROR_CODE) {
        this.setUpThreeDSecure(data.actionTokenId);
    } else {
        tracking.trackAction(ACTION_NAMES.APP_LOG, {
            appLogText: data.message || 'No message provided',
            appLogType: APP_LOG_TYPE.EDIT_PAYMENT_ERROR,
            appLogCode: data.code || 'No code provided',
        });

        this.openNotification(
            new NotificationBarModel({
                success: false,
                message: this.$getLocale('something_went_wrong_please_try_again_later'),
            }),
        );
    }
}
