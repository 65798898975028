import { ACCOUNT_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

export default {
    methods: {
        async syncServerData() {
            const res = await this.$http.doGet(`${ACCOUNT_XHR_BASE_PATH}fetch-subscription-data/`);
            await this.$store.dispatch('syncServerData', res.data.result);
        },
        async syncServerDataWithDeepAssign() {
            const res = await this.$http.doGet(`${ACCOUNT_XHR_BASE_PATH}fetch-subscription-data/`);
            await this.$store.dispatch('syncServerDataWithDeepAssign', res.data.result);
        },
    },
};
