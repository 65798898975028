<template>
    <div class="billing-payment-method">
        <div class="billing-payment-method__border"></div>

        <div class="billing-payment-method-info">
            <div class="billing-payment-method__current">
                <span class="billing-payment-method__text billing-payment-method__title">
                    {{ pageAttr.payment_method_on_file }}
                </span>
                <span class="billing-payment-method__info">{{ paymentInfo }}</span>
                <span
                    class="billing-payment-method__text billing-payment-method__copy"
                    v-if="!isPaymentMethodEditSPEnabled">
                    {{ pageAttr.payment_method_copy }}
                </span>
            </div>
            <div
                class="billing-payment-method__container-btn-edit"
                v-if="isPaymentMethodEditSPEnabled">
                <button
                    class="billing-payment-method__btn-edit"
                    data-testid="btn-edit-payment-method"
                    :aria-expanded="isPaymentMethodEditFormEnabled"
                    :disabled="isLoadingBillingInfo"
                    @click="handlePaymentMethodEditButton">
                    <div :class="iconCaretClassList"></div>
                    {{ btnEditPaymentMethodText }}
                </button>
            </div>
        </div>
        <div
            class="edit-payment-method-form"
            v-if="isPaymentMethodEditFormEnabled && isPaymentMethodEditSPEnabled">
            <SharedPaymentComponent
                :isEditPayment="true"
                :isFromPaymentSummary="true"
                :handleProcessedPaymentError="handleProcessedPaymentError"
                @update-payment-method-success="setPaymentMethodEditFormEnabled" />
            <div class="billing-payment-method__border"></div>
        </div>
    </div>
</template>

<script>
    import { capitalize } from 'aa/vue/components/account/helpers';
    import billingInfoMixin from 'aa/vue/components/account/billingInfoMixin';
    import { PAYMENT_PAYPAL } from 'aa/vue/constants/shared';
    import { mapState, mapGetters } from 'vuex';
    import { PAYMENT_METHOD_EDIT_SP } from 'aa/helpers/featureConstants';
    import SharedPaymentComponent from 'aa/vue/components/payment/SharedPaymentComponent';
    import { ACTION_NAMES, PAGE_TYPES } from 'services/Tracking';
    import { handleProcessedPaymentError } from 'aa/vue/components/payment/editPaymentUtil';
    import AccountPageTrackingService from 'aa/services/AccountPageTrackingService';
    import isEmpty from 'lodash/isEmpty';

    export default {
        name: 'BillingPaymentMethod',
        mixins: [billingInfoMixin],
        data: function () {
            return {
                isPaymentMethodEditFormEnabled: false,
                handleProcessedPaymentError: handleProcessedPaymentError,
            };
        },
        components: {
            SharedPaymentComponent,
        },
        props: {
            pageAttr: {
                type: Object,
            },
        },
        computed: {
            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),
            ...mapGetters(['featureIsActive']),
            isCard() {
                return this.billingInfo ? this.billingInfo.isTypeCard : false;
            },
            isPaypal() {
                return this.billingInfo ? this.billingInfo.isTypePaypal : false;
            },
            isGift() {
                return this.billingInfo ? this.billingInfo.isTypeGift : false;
            },
            paymentInfo() {
                if (this.isCard) {
                    return `${this.billingInfo?.cardType?.toUpperCase()} *${this.billingInfo?.lastFour}`;
                } else if (this.isPaypal) {
                    return capitalize(PAYMENT_PAYPAL);
                } else if (this.isGift) {
                    return 'N/A';
                }
            },
            isLoadingBillingInfo() {
                return this.isExSubscriber && isEmpty(this.$store.state.serverData.billingInfo);
            },
            btnEditPaymentMethodText() {
                return this.isPaymentMethodEditFormEnabled
                    ? this.pageAttr.cancel_edit_payment_method
                    : this.pageAttr.edit_payment_method;
            },
            iconCaretClassList() {
                return [
                    'icon',
                    'icon--caret',
                    this.isPaymentMethodEditFormEnabled ? 'icon--caret--up' : '',
                ];
            },
            isPaymentMethodEditSPEnabled() {
                return this.featureIsActive(PAYMENT_METHOD_EDIT_SP);
            },
            trackingService() {
                if (!this.$trackingService) {
                    return new AccountPageTrackingService();
                }
            },
        },
        methods: {
            handlePaymentMethodEditButton() {
                const trackingServiceInstance = this.$trackingService ?? this.trackingService;
                if (!this.isPaymentMethodEditFormEnabled) {
                    trackingServiceInstance.trackAction(ACTION_NAMES.TRACK_UPDATE_PAYMENT_METHOD, {
                        ctaText: this.btnEditPaymentMethodText,
                        pageURL: window.location.href,
                        pageType: PAGE_TYPES.PLAN_CHANGE_CONFIRMATION,
                    });
                } else {
                    trackingServiceInstance.trackAction(
                        ACTION_NAMES.TRACK_UPDATE_PAYMENT_METHOD_CANCEL,
                        {
                            ctaText: this.btnEditPaymentMethodText,
                            pageURL: window.location.href,
                            pageType: PAGE_TYPES.PLAN_CHANGE_CONFIRMATION,
                        },
                    );
                }

                this.isPaymentMethodEditFormEnabled = !this.isPaymentMethodEditFormEnabled;
            },
            setPaymentMethodEditFormEnabled(event) {
                this.isPaymentMethodEditFormEnabled = !event;
            },
            async addBillingInfo() {
                if (this.isExSubscriber) {
                    if (isEmpty(this.$store.state.serverData.billingInfo)) {
                        const billingInfo = await this.getBillingInfo();
                        this.$store.commit('addObjectToServerData', billingInfo);
                    }
                }
            },
        },
        watch: {
            isPaymentMethodEditFormEnabled(newValue) {
                this.$emit('is-payment-method-form-edit', newValue);
            },
        },
        created() {
            this.addBillingInfo();
        },
    };
</script>
