import { catanFlowType } from 'aa/vue/constants/aaBundle';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import vuetify from 'aa/vue/plugins/vuetify';
import 'es6-promise/auto';

import Router from 'aa/vue/Router';
import Store from 'aa/vue/stores/Store';

import SentryForVue from 'aa/services/SentryForVue';
import Funnel from 'aa/vue/components/Funnel';
import SvodHeader from 'aa/vue/components/SvodHeader';
import ShowtimeBundle from 'aa/vue/components/ShowtimeBundle';
import Debug from 'aa/vue/components/Debug';
import HttpService from 'services/HttpService';
import ShowsPickerService from 'services/ShowsPickerService';
import AbTestHttpService from 'abTests/AbTestHttpService';
import ProfileService from 'services/ProfileService';
import PurchaseFunnelGlobal from 'aa/vue/plugins/purchaseFunnel/PurchaseFunnelGlobal';
import localeBundlePlugin from 'plugins/localeBundle';
import { getBundleFromJSON } from 'services/LocalizationService';
import Sanitizer from 'apps/plugins/Sanitizer';
import BaseModalWrapper from 'aa/vue/atoms/BaseModalWrapper';
import CheckoutOptions from './models/CheckoutOptions';

// CBSCOMSUB-4696
import NotificationBar from 'aa/vue/components/NotificationBar';
import Feature, { AGE_GATE, SKIP_EXPLAINER_STEPS, DIRECT_LINK } from 'aa/helpers/featureConstants';
import { abTestUiTool } from 'shared/globalAbTestUiTool';
abTestUiTool();

export function getAppConfig() {
    try {
        return JSON.parse(document.getElementById('app-config').innerHTML);
    } catch (e) {}

    return {};
}

export function isCatanFlow() {
    return Feature.partnerBundleEnabled() && getAppConfig().flow.type === catanFlowType();
}

export function isFeatureFlagEnabled(flag) {
    const ff = getAppConfig().featureFlag;
    return ff && ff.hasOwnProperty(flag) && ff[flag] === true;
}
/**
 * Shared function used in multiple Apps
 * i.e. vue, vueEdu, vueGift
 * @returns {*|boolean}
 */
export function featureSkipExplainerStepsEnabled() {
    return isFeatureFlagEnabled(SKIP_EXPLAINER_STEPS);
}

export function featureAgeGateEnabled() {
    return isFeatureFlagEnabled(AGE_GATE);
}

export function directLinkEnabled() {
    return isFeatureFlagEnabled(DIRECT_LINK);
}
/**
 * @param stepModules {object}
 * @param orderedStepClassList {array}
 * @returns {[]}
 */
export function getSteps(stepModules, orderedStepClassList) {
    const steps = [];

    if (orderedStepClassList) {
        orderedStepClassList.forEach((stepClass) => {
            if (stepModules[stepClass]) {
                try {
                    steps.push(new stepModules[stepClass]());
                } catch (e) {}
            }
        });
    }

    return steps;
}

export function initApp(routerConfig = {}) {
    if (CBS.Registry.Features.braze_sdk === true) {
        import('shared/Braze').then((Braze) => {
            new Braze.default();
        });
    }

    Vue.config.productionTip = false;

    Vue.use(VueRouter);
    Vue.use(Vuex);
    Vue.use(PurchaseFunnelGlobal);
    Vue.use(Sanitizer);
    Vue.use(localeBundlePlugin);

    let serverData = routerConfig.appConfig || getAppConfig();
    const store = Store();

    routerConfig.store = store;

    const http = new HttpService();
    const localeBundle = getBundleFromJSON();
    store.$http = http;
    Vue.prototype.$http = http;

    routerConfig.store = store;
    routerConfig.regionBaseUrl =
        location.pathname.split('/')[1] === 'intl' ? '/intl' : serverData.regionBaseUrl;
    const router = Router(routerConfig);

    store.$spicker_service = new ShowsPickerService();
    store.$profile_service = new ProfileService();
    // Set data here, since vue lifecycle hooks are called after router hooks
    if (serverData.partnerAuthAttributes) {
        //If this info is available set it in the store
        store.commit('partner/setPartnerAuthAttributes', serverData.partnerAuthAttributes);
    }

    store.$abTestHttpService = new AbTestHttpService({ isAA: true });
    store.$abTestHttpService.setState(store.state);

    const app = new Vue({
        name: 'App',
        router,
        store,
        vuetify,
        data: {
            serverData: serverData,
            debug: false,
        },
        beforeCreate: function () {
            if (serverData.recurlyPublicKey) {
                store.dispatch('payment/insertRecurlyScript', serverData);
            }
        },
        created: function () {
            store.commit('setImageHost', this.serverData.imageHost);
            store.commit('tracking/setIntcid', encodeURIComponent(this.$route.query.intcid));

            store.commit('setToken', this.serverData.tk_trp);

            store.commit('setLocaleDictionary', localeBundle);

            if (this.isSameUser()) {
                const selectedPlanTier = JSON.parse(sessionStorage.getItem('planTier'));
                if (selectedPlanTier && this.serverData.multiSubPlanPicker) {
                    store.dispatch('plan/setPlanTier', selectedPlanTier);
                }

                const selectedPlanType = JSON.parse(sessionStorage.getItem('planType'));
                if (selectedPlanType && this.serverData.multiSubPlanPicker) {
                    store.dispatch('plan/setPlanType', { planType: selectedPlanType });
                }
            }

            let mspEnabled = this.$store.getters.featureIsActive('multi_sub_plan_picker_enabled');
            if (mspEnabled) {
                localStorage.removeItem('selectedPlan');
            }

            const selectedPlan = CheckoutOptions.get()?.selectedPlan;
            if (selectedPlan) {
                store.dispatch('plan/setSelectedPlan', selectedPlan);
                store.dispatch('plan/setPlanType', {
                    planType: selectedPlan.planType,
                });
            }

            let promo = null;

            if (this.serverData.promo !== null || this.$route.query.promo) {
                promo = this.serverData.promo || this.$route.query.promo || null;
                promo = promo ? encodeURIComponent(promo) : null;
            }

            if (this.serverData?.deltaBranchJourneyKey) {
                store.commit('setDeltaBranchJourneyKey', this.serverData.deltaBranchJourneyKey);
            }

            store.commit('setFeatureFlags', this.serverData?.featureFlag);
            store.dispatch('updateCoupon', promo);
            store.commit('setPromo', promo);
        },
        components: {
            BaseModalWrapper,
            Funnel,
            SvodHeader,
            Debug,
            ShowtimeBundle,
            // CBSCOMSUB-4696
            NotificationBar,
        },
        methods: {
            isSameUser() {
                const userId = CBS.Registry.user?.id;

                let sessionUserId = JSON.parse(sessionStorage.getItem('userId')) || '';
                if (sessionUserId) {
                    if (userId === parseInt(sessionUserId) || sessionUserId === '') {
                        if (userId) {
                            sessionStorage.setItem('userId', userId);
                        }
                        return true;
                    } else {
                        sessionStorage.removeItem('userId');
                        sessionStorage.removeItem('planType');
                        sessionStorage.removeItem('planTier');
                        localStorage.removeItem('selectedPlan');
                        return false;
                    }
                }

                sessionStorage.setItem('userId', userId);
                return true;
            },
        },
        render: (createElement) =>
            createElement('div', [
                createElement(Funnel),
                createElement(BaseModalWrapper, { displayCloseButton: false }),
            ]),
    }).$mount('#app-placeholder');

    SentryForVue(app);
}
