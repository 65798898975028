import PageAttributesStore from 'aa/vue/stores/account/modules/PageAttributesStore';
import mutations from 'aa/vue/stores/account/mutations';

export default {
    namespaced: true,
    mutations,
    actions: {
        loadServerData({ commit, rootGetters, dispatch }) {
            commit('parseData', {
                authToken: rootGetters.getServerDataByKey('authToken'),
                billingInfo: rootGetters.getServerDataByKey('billingInfo'),
                currentSubscription: rootGetters.getServerDataByKey('currentSubscription'),
                subscriptionStatus: rootGetters.getServerDataByKey('subscriptionStatus'),
                products: rootGetters.getServerDataByKey('products'),
                userProduct: rootGetters.getServerDataByKey('userProduct'),
                pendingProduct: rootGetters.getServerDataByKey('pendingProduct'),
                promo: rootGetters.getServerDataByKey('promo'),
                plans: rootGetters.getServerDataByKey('plans'),
            });

            return dispatch('pageAttributes/loadServerData');
        },
    },
    modules: {
        pageAttributes: PageAttributesStore,
    },
};
