import get from 'lodash/get';
import { ACTION_NAMES, APP_LOG_TYPE, tracking } from 'services/Tracking';
import {
    SUBSCRIBED_OR_SUSPENDED,
    THREE_D_SECURE_ERROR_CODE,
    SUBSCRIPTION_ALREADY_IN_USE,
} from 'aa/vue/constants/shared';
import { scrollTo } from 'helpers/scrollTo';
import { SHOW_PICKER, MFE_ENABLED, MFE_USERPROFILES_ENABLED } from 'helpers/featureConstants';
import { setCookie } from 'helpers/VanillaCookie';
import { FLOW_TYPE } from 'models/FlowModel';
import { NFL_BANNER_FLAG } from 'helpers/sharedConstants';
import { SHOW_PICKER_PATH } from 'aa/vue/constants/aaRoutes';
import billingInfoMixin from 'aa/vue/components/account/billingInfoMixin';
import isEmpty from 'lodash/isEmpty';

export function displayFeedbackToUser(message) {
    this.formErrorMessage = message;
}

export function handleProcessedPayment(resp) {
    const couponCode = get(this, 'coupon.recurlyInfo.code');
    const trackingParams = {
        ...resp.tr,
        purchasePromoCode: couponCode,
        userRegType: this.$store.getters['flow/isRendezvous']
            ? 'rendezvous'
            : this.$store.getters['flow/userRegType'],
        pageType: 'svod_complete',
    };
    if (
        this.$store.getters['flow/isRendezvous'] &&
        this.$root.serverData.enhancedTrackActivationSuccess
    ) {
        trackingParams.activationDeviceFamily = this.$root.serverData.flow.partner || '';
    }
    tracking.trackPayment(trackingParams);
    CBS.CombinedCookie.set('graph', 'couponCode', couponCode || null);

    this.submitStatus = this.$getLocale('the_account_has_been_created_successfully');

    // set cookie & localStorage to show nfl game pass banner on home page and hide for showtime bundle
    if (
        this.$store.getters['flow/type'] === FLOW_TYPE.DEFAULT &&
        !this.$store.getters['bundleShowtime']
    ) {
        setCookie(NFL_BANNER_FLAG, '1', { path: '/' });
        window.localStorage.setItem(NFL_BANNER_FLAG, '0');
    }

    // Add short delay to allow tracking call to complete
    setTimeout(() => {
        localStorage.removeItem('selectedPlan');
        localStorage.removeItem('promoOffer');
        if (this.$store.getters['flow/isRendezvous']) {
            window.location.href = this.$store.getters['flow/destinationUrl'];
        } else {
            const showMFEUserProfile =
                this.$store.state.featureFlags[MFE_ENABLED] &&
                this.$store.state.featureFlags[MFE_USERPROFILES_ENABLED];

            if (this.$store.state.featureFlags[SHOW_PICKER]) {
                let nextStep = this.$store.getters['flow/getNextStep'];
                if (nextStep) {
                    if (nextStep.name === 'ShowsPicker' && showMFEUserProfile) {
                        window.location.href = SHOW_PICKER_PATH;
                        return;
                    } else {
                        this.$router.push({ name: nextStep.name });
                        return;
                    }
                }
            }
            window.location.href = '/';
        }
    }, 500);
}

export function handleProcessedPaymentError(err, trackingParams = {}) {
    const code = get(err, 'data.code') || 'No code provided';
    const errorMessage = err.message || 'No message provided';
    this.formIsProcessing = false;

    if (code === THREE_D_SECURE_ERROR_CODE) {
        this.setUpThreeDSecure(err.data.actionTokenId);
    } else if (code === SUBSCRIPTION_ALREADY_IN_USE) {
        tracking.trackAction(ACTION_NAMES.APP_LOG, {
            appLogText: errorMessage,
            appLogType: SUBSCRIPTION_ALREADY_IN_USE,
            appLogCode: code,
            ...trackingParams,
        });
    } else if (err.message || err.data.code) {
        tracking.trackAction(ACTION_NAMES.APP_LOG, {
            appLogText: errorMessage,
            appLogType: APP_LOG_TYPE.PURCHASE_ERROR,
            appLogCode: code,
            ...trackingParams,
        });

        if (errorMessage === SUBSCRIBED_OR_SUSPENDED) {
            window.location.href = '/';
        } else {
            this.formErrorMessage =
                err.data && err.data.code ? this.$getLocale(err.data.code) : err.message;

            this.formErrorCode = err?.data?.errorCode;

            scrollTo.top();
        }
    }
}

export async function redirectToSummary(store) {
    if (isEmpty(store.state.serverData.billingInfo)) {
        const billingInfoBounded = await billingInfoMixin.methods.getBillingInfo.bind(store);
        const billingInfo = await billingInfoBounded();
        store.commit('addObjectToServerData', billingInfo);
    }

    if (
        store.state.serverData.billingInfo.isTypeCard &&
        store.state.serverData.billingInfo.isExpired
    ) {
        return undefined;
    }
    const regionBaseUrl = store.state.serverData.regionBaseUrl;
    return `${regionBaseUrl}/account/signup/summary/`;
}
