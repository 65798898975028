/*
 * AAAbTestModuleLoader
 * Framework level class not to be modified for AbTest implementations
 * */

import { AbTestModuleLoader } from 'abTests/lib/AbTestModuleLoader';

/*
 * This constant promise is required to map classes accordingly for AA AB Test classes
 * DO NOT REMOVE
 * */
const AAImportPromise = (moduleName) => {
    return import(`../testClasses/${moduleName}`);
};

const AbTestPreload = 'ab-test-preload'; // class to hide elements by default, before A/B tests run
const AbTestReady = 'ab-test-ready'; // class to fade in elements w/ applicable A/B test styling

export class AAAbTestModuleLoader extends AbTestModuleLoader {
    constructor() {
        super({
            importPromise: AAImportPromise,
            isAA: true,
        });
    }

    /**
     * A/B tests on the upsell page usually hide default elements in order to display variations w/o
     * any flickering. In the event that a test is turned off, since the upsell page is cached,
     * we want to ensure elements will always appear.
     */
    afterRun() {
        // Create a custom event
        const customEvent = new CustomEvent('loadAbTestsVariantsIntoVue', {
            detail: this.testVariants,
        });
        // Dispatch the custom event on an element (e.g., the document)
        document.dispatchEvent(customEvent);

        const upsellElements = document.querySelectorAll('.' + AbTestPreload);
        upsellElements.forEach((element) => {
            // remove preload class used to hide elements by default
            element.classList.remove(AbTestPreload);
            // add ready class to fade-in elements that were hidden
            element.classList.add(AbTestReady);
            // remove class after fade-in to avoid conflicts w/ other existing animations
            element.classList.remove(AbTestReady);
        });
    }
}
