import CheckboxFormConfig from 'aa/vue/components/account/formConfigs/classes/CheckboxFormConfig';
import PageAttributeService from 'aa/vue/services/PageAttributeService';
import ValidationService from 'services/ValidationService';
import isInViewPort from 'helpers/isInViewPort';
import { ButtonState } from 'models/ButtonConfig';
import { scrollTo } from 'helpers/scrollTo';

let validationMessagesLoad;

export default {
    data: () => {
        return {
            fieldConfigs: {},
            fieldLabels: {},
            formData: {},
            fieldErrors: {},
            validationService: null,
            validationMessages: {},
            formIsValid: false,
            formIsProcessing: false,
            formErrorMessage: null,
            formErrorCode: null,
            formName: '',
        };
    },

    computed: {
        formFieldList() {
            for (let fieldName in this.fieldConfigs) {
                if (!this.fieldConfigs.hasOwnProperty(fieldName)) continue;
                let field = this.fieldConfigs[fieldName];
                if (field.type === 'checkbox') {
                    let fieldConfig = new CheckboxFormConfig(field);
                    fieldConfig.wrapperClass += ' cbs-checkbox--legal left';
                    this.fieldConfigs[fieldName] = fieldConfig;
                }

                if (this.getFieldLabel) {
                    let label = this.getFieldLabel(fieldName);
                    if (label) {
                        // TODO: switch to just one label key! remove inputLabel and replace by label everywhere
                        this.fieldConfigs[fieldName].label = this.fieldConfigs[
                            fieldName
                        ].inputLabel = label;
                    }
                }
            }

            return this.fieldConfigs;
        },

        submitButtonState() {
            if (this.formIsProcessing) {
                return ButtonState.Loading;
            } else if (!this.formIsValid) {
                return ButtonState.Disabled;
            }

            return ButtonState.Enabled;
        },
    },

    methods: {
        updateFormData(formData) {
            if (formData && typeof formData === 'object') {
                this.formData = Object.assign({}, this.formData, formData);
            }
        },

        setFieldErrors(fieldErrors) {
            if (fieldErrors) {
                if (fieldErrors instanceof Map) {
                    let errorObject = {};

                    fieldErrors.forEach((value, key, map) => {
                        errorObject[key] = value;
                    });

                    this.fieldErrors = errorObject;
                } else if (typeof fieldErrors === 'object') {
                    this.fieldErrors = fieldErrors;
                }
            } else {
                this.fieldErrors = {};
            }
        },

        async validateField({ name, on }) {
            if (this.validationService) {
                let validation = this.validationService.validateOne({
                    name,
                    on,
                    formData: this.formData,
                });

                if (validation.isValid) {
                    this.$set(this.fieldErrors, name, null);
                    this.validateForm({
                        on,
                    });
                } else {
                    this.formIsValid = false;
                    // todo: wait for validation messages before setting this, useComputed or store/computed somehow for validation messages/fieldErrors?
                    await validationMessagesLoad;
                    this.$set(this.fieldErrors, name, validation.message);
                }
            } else {
                this.formIsValid = true; // fallback
            }
        },

        validateForm({ on }, e) {
            if (this.validationService) {
                let validation = this.validationService.validateAll(this.formData);

                if (on === 'submit') {
                    this.setFieldErrors(validation.errors);

                    if (!validation.isValid) {
                        this.scrollToFirstErrorField();
                    }
                } else if (validation.isValid) {
                    this.setFieldErrors({}); // clears field match errors
                }

                this.formIsValid = validation.isValid;
            } else {
                this.formIsValid = true; // fallback
            }

            if (on === 'submit' && this.formIsValid) {
                this.handleValidForm(e);
            }
        },

        handleSubmit(e) {
            if (this.formIsProcessing) {
                return false;
            }
            if (this.formIsValid) {
                this.handleValidForm(e);
            } else {
                this.validateForm(
                    {
                        on: 'submit',
                    },
                    e,
                );
            }
        },

        setValidationService(validationService, overrideRegion = null, overrideLocale = null) {
            // TODO: how to make error fields reactive?
            validationMessagesLoad = PageAttributeService.get(
                ['validation'],
                this.$store.state.user.statusCode,
                this.$http,
                overrideRegion,
                overrideLocale,
            )
                .then((messages) => {
                    // TODO: check validation message based on current region as soon as new address fields will be available
                    Object.assign(this.validationMessages, messages);
                })
                .catch((e) => {});

            if (validationService instanceof ValidationService) {
                this.validationService = validationService;
            } else {
                this.validationService = new ValidationService(
                    this.formFieldList,
                    this.validationMessages,
                );
            }
        },

        setFieldConfigs(fieldConfigs) {
            if (fieldConfigs) {
                if (Array.isArray(fieldConfigs)) {
                    let configs = {};

                    fieldConfigs.forEach((config) => {
                        configs[config.name] = config;
                    });

                    this.fieldConfigs = configs;
                } else if (typeof fieldConfigs === 'object') {
                    this.fieldConfigs = fieldConfigs;
                    for (const fieldName in this.fieldConfigs) {
                        const config = this.fieldConfigs[fieldName];

                        if (!config.id) {
                            config.id = `field-${config.name}`;
                        }
                    }
                }
            } else {
                this.fieldConfigs = {};
            }
        },

        getFirstErrorField() {
            let firstErrorField;

            Object.keys(this.fieldConfigs).some((name) => {
                let hasMessage = this.fieldErrors[name] && this.fieldErrors[name] !== '';

                if (hasMessage) firstErrorField = document.querySelector('.grid-item--' + name);

                return hasMessage;
            });

            return firstErrorField;
        },

        /**
         * Will scroll if first error is not in view port.
         */
        scrollToFirstErrorField() {
            let firstErrorField = this.getFirstErrorField();

            if (firstErrorField && !isInViewPort(firstErrorField)) {
                scrollTo.element(firstErrorField);
            }
        },
    },
};
