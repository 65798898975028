//START CBSCOMSUB-8934 ProminentSignUpCTA
import { AbTest } from 'abTests/lib/AbTest';
const SEE_PLAN_CONTAINER_CLASS = 'see-plans-cta-container';
const SEE_PLAN_DETAILS = 'SEE PLAN DETAILS';

const NEW_HERO_VARIANT_2_IMAGE =
    'https://cms.paramountplus.com/base/gcs/asset/asset/22-10-2024-17-37/us/us-en/up-main-us-sept2024_1030x1182.jpg';
const PROMINENT_CTA_BOTTOM_VARIANT = 'prominent_button_position_botttom';

export class ProminentSignUpCta extends AbTest {
    onRun() {
        this.aaUpsell = document.querySelector('.aa-primary-upsell');

        this.swapHeroImage();

        //resolving flicker
        this.heroSection = this.aaUpsell.querySelector('.hero .hero--center');

        //only applies to variant 3
        //All the content in lockup (excluding “See Plans” CTA) is centered vertically in the hero bucket
        // Same requirements as Variant 1, with the addition of the See Plans CTA
        // Vertically center “See Plans” CTA between bottom of “Try it Free” CTA and Bottom of hero bucket
        //Clicking see plan details would take you to the Plans Table
        if (this.activeVariant === 'prominent_button_see_plans') {
            this.heroCta = this.heroSection.querySelector('.grid .grid-item .hero__cta') ?? null;
            this.seePlansCtaContainer = this.generateTerciarySeePlansCtaContainer();
            this.heroCta.insertAdjacentElement('afterend', this.seePlansCtaContainer);
            this.setScrollListenerSeePlansCta();
        }
    }

    swapHeroImage() {
        if (this.activeVariant === PROMINENT_CTA_BOTTOM_VARIANT) {
            this.aaUpsell.style.backgroundImage = "url('" + NEW_HERO_VARIANT_2_IMAGE + "')";
        }
    }

    /**
     * generate SEE Plans CTA containers in following html format
     * <div class="see-plans-cta-container">
     *     <a class="button focusable js-cta-tracking terciary">
     *         <div class="button__text">{CTA Variable}
     *         </div>
     *    </a>
     * </div>
     *
     * @returns HTMLDivElement
     */
    generateTerciarySeePlansCtaContainer() {
        const seePlansCtaContainer = document.createElement('div');
        seePlansCtaContainer.classList.add(SEE_PLAN_CONTAINER_CLASS);

        // Create a new button element
        const seePlansCta = document.createElement('a');
        seePlansCta.classList.add('button', 'js-cta-tracking', 'terciary');

        const seePlansButtonText = document.createElement('div');
        seePlansButtonText.classList.add('button__text');
        seePlansButtonText.textContent = SEE_PLAN_DETAILS ?? '';
        seePlansCta.append(seePlansButtonText);

        // Append the button to the div
        seePlansCtaContainer.appendChild(seePlansCta);
        return seePlansCtaContainer;
    }

    /**
     * if User clicks on See Plans button, scrlls to PLAN TABLE sections on upsell.
     * @returns void
     */
    setScrollListenerSeePlansCta() {
        const seePlanCta = this.heroSection.querySelector('.see-plans-cta-container a') ?? null;
        seePlanCta.addEventListener('click', () => {
            om.trackAction('trackSeePlansCTA', {
                ctaText: SEE_PLAN_DETAILS,
            });
            const plansTableContainer = document.getElementsByClassName('plans')[0] ?? null;
            if (plansTableContainer) {
                plansTableContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        });
    }
}

export const hasProminentCtaExperiment = () => {
    return typeof CBS?.AbTestsConfigs?.modules?.ProminentSignUpCta !== 'undefined';
};

//END CBSCOMSUB-8934 ProminentSignUpCTA
