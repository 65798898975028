<template>
    <div>
        <div
            class="payment-layout__summary__agreement summary-page__upgrade-message-gap"
            v-html="displayMessage"></div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { SWITCH_DIRECTION_UPGRADE } from 'aa/vue/constants/shared';

    export default {
        name: 'SummaryUpgradeDowngrade',
        props: {
            pageAttr: {
                type: Object,
            },
        },
        computed: {
            ...mapGetters('plan', ['activeCadenceOptions']),
            ...mapGetters('user', ['isNoTrialUser']),
            plan() {
                let selectedPlan = this.$store.getters['plan/getSelectedPlan'];

                return selectedPlan;
            },
            isUpgrade() {
                switch (this.plan?.switchDirection?.toLowerCase()) {
                    case SWITCH_DIRECTION_UPGRADE:
                        return true;
                    default:
                        return false;
                }
            },
            displayMessage() {
                return this.pageAttr[`${this.isUpgrade ? 'upgrade' : 'downgrade'}_legal_copy_ta`];
            },
        },
    };
</script>
