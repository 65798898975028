import { ACCOUNT_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

export default {
    computed: {
        billingInfo() {
            return this.$store.getters.getServerDataByKey('billingInfo');
        },
    },
    methods: {
        async getBillingInfo() {
            const res = await this.$http.doGet(`${ACCOUNT_XHR_BASE_PATH}fetch-billing-data/`);
            const data = await res.data.result;

            return data;
        },
    },
};
