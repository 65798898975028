<template>
    <div class="payment-summary" :class="countryClass">
        <div class="payment-summary__two-col">
            <h4 class="payment-summary__header" data-ci="payment-summary__header">
                {{ pageAttr.billing_summary_title }}
            </h4>
            <a
                v-if="changeLinkEnabled"
                @click="handleChangeClicked"
                class="payment-summary__header-change">
                {{ pageAttr.billing_summary_change_plan_cta }}
            </a>
            <div v-if="!changeLinkEnabled"></div>
            <div data-testid="currentPlan" data-ci="payment-summary__currentPlan">
                {{ planLabel }}
            </div>
            <div data-testid="currentPrice" data-ci="payment-summary__currentPrice">
                {{ internationalizedPriceWithUnit }}
                <span v-if="featureAsterisk && !isExSubscriber">*</span>
            </div>
        </div>
        <div v-if="legalAttributesAreValid" class="payment-summary__section-border" />
        <div v-if="legalAttributesAreValid" class="payment-summary__two-col">
            <div
                class="payment-summary__terms-service"
                data-testid="terms-service"
                data-ci="payment-summary__terms-service">
                {{ pageAttr.plan_info }}
            </div>
            <a
                class="payment-summary__legal-overlay-open"
                @click="handleOpenModal"
                data-testid="legal-overlay-open"
                data-ci="payment-summary__legal-overlay-open">
                {{ pageAttr.learn_more_copy }}
            </a>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import {
        PRICE_WITH_ASTERISK,
        PAYMENT_SUMMARY_CHANGE_LINK_ENABLED,
    } from 'helpers/featureConstants';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import { ACTION_NAMES, tracking } from 'services/Tracking';

    export default {
        name: 'BillingPlanSummary',

        props: {
            internationalizedPriceWithUnit: {
                type: String,
            },
            countryClass: {
                type: String,
            },
            pageAttr: {
                type: Object,
            },
            isFromDirect: {
                type: Boolean,
                default: false,
            },
        },

        mixins: [commonMixins],

        data: function () {
            return {
                isPaymentPage: true,
                featureAsterisk: false,
            };
        },

        methods: {
            handleChangeClicked() {
                if (this.isSwitchPlan) {
                    tracking.trackAction(ACTION_NAMES.TRACK_SWITCH_PLAN, {
                        pageType: 'svod_switch-plan',
                        ctaText: this.pageAttr.billing_summary_change_plan_cta,
                        screenName: '/switch-plan/',
                        productCurrentSku: this.getSelectedPlan.recurlyCode,
                        pickPlanType: this.planLabel,
                        switchPlanClick: '1',
                    });
                    this.$router.push({ name: 'AccountTier' });
                } else {
                    tracking.trackAction(ACTION_NAMES.TRACK_CHANGE_YOUR_PLAN, {
                        pageUrl: window.location.href,
                        pageType: 'svod_payment',
                        pickPlanType: this.planLabel,
                    });
                    this.$router.push({ name: 'tier' });
                }
            },
            handleOpenModal() {
                if (!this.legalAttributesAreValid) {
                    return;
                }
                this.openModal('BillingPlanLegalOverlayModal', this.selectedLegalAttributes);
                let pageType = this.getPageType(this.$route.params.directLinkFlow);
                tracking.trackAction(ACTION_NAMES.LEARN_MORE_CTA, {
                    pageUrl: window.location.href,
                    pageType,
                });
            },
            getPageType(directLinkFlow = null) {
                return directLinkFlow
                    ? `winback_${directLinkFlow}_directLink_payment`
                    : 'svod_payment';
            },
        },

        computed: {
            selectedLegalAttributes() {
                return this.$store.getters['plan/getLegalOverlayAttributes'];
            },
            legalAttributesAreValid() {
                if (
                    !this.selectedLegalAttributes ||
                    !this.selectedLegalAttributes.legal_copy_header ||
                    !this.selectedLegalAttributes.chosen_legal_copy_desktop
                ) {
                    return false;
                }
                return true;
            },
            ...mapGetters(['featureIsActive']),
            ...mapGetters('plan', ['getSelectedPlan']),
            isSwitchPlan() {
                return this.$route.name !== 'PAYMENT_SUMMARY';
            },
            planLabel() {
                const planLabel = this.$store.getters['plan/getSelectedPlanLabelKey'];

                return (
                    this.$store.getters['plan/getSelectedPlan']?.localizedPlanTitle ??
                    this.$getLocale(planLabel)
                );
            },

            changeLinkEnabled() {
                return (
                    this.featureIsActive(PAYMENT_SUMMARY_CHANGE_LINK_ENABLED) && !this.isFromDirect
                );
            },

            displayChangePlanLink() {
                return (
                    this.$store.getters['plan/getPlans'].length > 1 &&
                    !this.$store.getters['flow/isEdu']
                );
            },

            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),

            getSignupRouterLink() {
                return '/account/signup/pickplan/';
            },
        },
        created() {
            this.featureAsterisk = this.featureIsActive(PRICE_WITH_ASTERISK);
        },
    };
</script>
