<!-- START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
<template>
    <div :class="getPlanBoxClassList" @click="handleClick">
        <PillComponent
            v-if="needsBestValuePill"
            :className="getPillClass"
            :textValue="getBestValueText" />
        <div class="plan-heading-ab-test">
            {{ planHeading }}
        </div>
        <div v-if="getTrialCopy" class="plan-copy-ab-test">
            {{ getTrialCopy }}
        </div>
        <div class="plan-price-copy-ab-test">
            <span v-html="getPlanPriceCopy"></span>
            <span v-if="isAnnualPlan" class="effective" v-html="monthlyEffectivePriceCopy"></span>
        </div>
    </div>
</template>

<script>
    import PickAPlanBox from './PickAPlanBox';
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';
    import PillComponent from 'aa/vue/components/PillComponent';

    export default {
        name: 'AbTestNativeAppViewPlansBox',
        extends: PickAPlanBox,
        mixins: [AbTestPlanTilesAboveFoldMixin],
        prop: {
            plan: {
                type: Object, // Plan.js
                required: true,
            },
            idx: {
                type: Number,
                required: true,
            },
        },
        mounted() {
            if (this.highlightedPlanIdx === -1 && this.idx === 0) {
                // click first plan if not already selected
                this.handleClick();
            } else if (this.highlightedPlanIdx === this.idx) {
                // set the current features list for the selected plan
                this.$emit('setFeaturesList', this.featureList);
            }
        },
        methods: {
            handleClick() {
                this.$store.commit('variant/setHighlightedPlanIdx', this.idx);
                this.$emit('setFeaturesList', this.featureList);
            },
            notifyParent() {
                if (this.highlightedPlanIdx === this.idx) {
                    this.$emit('setFeaturesList', this.featureList);
                }
            },
        },
        components: {
            PillComponent,
        },
        computed: {
            getPlanBoxClassList() {
                let classList = ['plan-box-ab-test'];
                if (this.highlightedPlanIdx === this.idx) {
                    classList.push('plan-highlighted');
                }
                return classList;
            },
            getPlanPriceCopy() {
                const afterTrialCopy = this.planPriceCopy.split('/');
                if (afterTrialCopy.length >= 1) {
                    afterTrialCopy[1] =
                        "<span class='after-trial'>/" + afterTrialCopy[1] + '</span>';
                }
                return afterTrialCopy.join('').replace('month', 'mo').replace('year', 'yr');
            },
            getBestValueText() {
                return 'BEST VALUE';
            },
        },
        watch: {
            featureList: {
                handler: function () {
                    this.notifyParent();
                },
                deep: true,
            },
            highlightedPlanIdx: function () {
                this.notifyParent();
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
