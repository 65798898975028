import TrackingService from 'services/TrackingService';

export const pageType = Object.freeze({
    SWITCH_PLAN: 'svod_switch-plan',
    CROSSGRADE: 'svod_crossgrade',
    DOWNGRADE: 'svod_downgrade',
    UPGRADE: 'svod_upgrade',
});

export const logType = Object.freeze({
    SWITCH_PLAN_ERROR: 'Switch Plan Error',
    SIGN_OUT_ALL_DEVICES_EMAIL_ERROR: 'Sign out of all devices email failure',
});

export const actionName = Object.freeze({
    ACCOUNT_LANDING_PAGE: 'account',
    ADDON_ADD: 'trackAddShowtimeBundleAccount',
    ADDON_ADD_CANCEL: 'trackGoBackAddShowtimeBundle',
    ADDON_ADD_COMPLETE: 'trackAddShowtimeBundleComplete',
    ADDON_REMOVE: 'trackCancelRemoveShowtimeBundle',
    ADDON_REMOVE_CANCEL: 'trackGoBackRemoveShowtimeBundle',
    ADDON_REMOVE_COMPLETE: 'trackRemoveShowtimeBundleComplete',
    ADDON_CANCEL: 'trackAddOnsCancel',
    ADDON_UPDATE: 'trackUpdateSubscription',
    CANCEL_COMFIRMATION: 'cancel_confirmation',
    CANCEL_OFFER_MODAL: 'cancel_offer_modal',
    CANCEL_REASON_MODAL: 'cancel_reason_modal',
    CANCEL_SUB_NO: 'trackCancelSubscriptionNoDontCancel',
    CANCEL_SUB_YES: 'trackCancelSubscriptionYesCancel',
    CANCEL_SUB: 'trackCancelSubscription',
    CANCEL_SURVEY_BACK: 'trackCancelSubscriptionBack',
    CREDIT_CARD_CANCEL: 'trackUpdateCreditCardCancel',
    CREDIT_CARD_SUBMIT: 'trackUpdateCreditCardSubmit',
    CROSSGRADE_COMPLETE: 'trackCrossgradeComplete',
    DECLINE_OFFER_MODAL: 'trackCancelSubscriptionOfferNoThanks',
    EDIT_EMAIL_CANCEL: 'trackEditEmailCancel',
    EDIT_EMAIL_SAVE: 'trackEditEmailSave',
    EDIT_EMAIL_SUCCESS: 'trackEditEmailSuccess',
    EDIT_EMAIL: 'trackEditEmail',
    EDIT_PASSWORD_CANCEL: 'trackEditPasswordCancel',
    EDIT_PASSWORD_SAVE: 'trackEditPasswordSave',
    EDIT_PASSWORD: 'trackEditPassword',
    EDIT_PAYMENT: 'trackEditPayment',
    CANCEL_EXIT: 'trackExitCancelOffer',
    EDIT_PLAN: 'trackEditPlan',
    CHANGE_PLAN: 'trackChangeYourPlan',
    FAQ: 'trackFAQ',
    MANAGE_ADDONS: 'trackManageAddons',
    ACTIVATE_ADDON: 'trackAccountPageShowtimeActivate',
    PARENTAL_CONTROLS_ON: 'trackParentalControlsOn',
    PARENTAL_CONTROLS_OFF: 'trackParentalControlsOff',
    PARENTAL_CONTROLS_CANCEL: 'trackParentalControlsCancel',
    PARENTAL_CONTROLS_EDIT: 'trackEditParentalControls',
    PARENTAL_RELOGIN_SUBMIT: 'trackPasswordParentalControlSubmit',
    PARENTAL_RELOGIN_CANCEL: 'trackPasswordParentalControlCancel',
    RECAPTCHA_FAILURE: 'trackCaptchaFailure',
    RECAPTCHA_SUCCESS: 'trackCaptchaSuccess',
    RECAPTCHA_VERIFY: 'trackCaptchaVerify',
    RECAPTCHA_VIEW: 'trackCaptchaView',
    REDEEM_COUPON: 'trackRedeemACoupon',
    REEDEM_NOW: 'trackCancelSubscriptionOfferRedeemNow',
    SELECT_DEVICE: 'trackSelectDevice',
    SIGN_OUT: 'trackSignOut',
    SIGN_OUT_ALL_DEVICES_CTA: 'trackSignOutAllDevicesCTA',
    SIGN_OUT_ALL_DEVICES_EMAIL_SENT: 'trackEmailSentToSignOutAllDevices',
    SWITCH_PLAN_CANCEL: 'trackSwitchPlansCancel',
    SWITCH_PLAN_UPGRADE_OFFER: 'trackUpgradePromo',
    SWITCH_PLAN_UPGRADE: 'trackUpgrade',
    SWITCH_PLAN: 'trackSwitchPlan',
    SWITCH_TO_ANNUAL: 'trackSwitchToAnnual',
    SWITCH_TO_MONTHLY: 'trackSwitchToMonthly',
    SWITCH_TO_PAYPAL: 'trackSwitchtoPaypalButtonSelect',
    TRACK_CANCEL_COMPLETE_CANCELLATION: 'trackCancelSubscriptionCompleteCancellation',
    ANNUAL_PLAN_SWITCH: 'trackAnnualPlanSwitch',
    USER_PROFILE_MANAGE: 'trackManageProfiles',
    ACCOUNT_SWITCH_PLAN_SELECT: 'trackAccountSwitchPlanSelect',
    ACCOUNT_SWITCH_BILLING_PLAN_SELECT: 'trackAccountSwitchBillingSelect',
    SWITCH_PLAN_DOWNGRADE_COMPLETE: 'trackDowngradeComplete',
    SWITCH_PLAN_UPGRADE_COMPLETE: 'trackUpgradeComplete',
    CONTINUOUS_PLAY_SWITCH_OFF: 'trackAutoPlayOff',
    CONTINUOUS_PLAY_SWITCH_ON: 'trackAutoPlayOn',
    UPDATE_EDITORIAL_EMAIL_PREFERENCE: 'trackUpdateEditorialEmailPreference',
});

const paramKey = Object.freeze({
    PARENTAL_SWITCH_OFF: 'eventParentalControlsOff',
    PARENTAL_SWITCH_ON: 'eventParentalControlsOn',
    PARENTAL_RATING: 'parentalControlsRatingCategory',
    PARENTAL_LOCK_LIVE_TV: 'lockLiveTV',
});

class AccountPageTrackingService extends TrackingService {
    constructor(timeout) {
        timeout = typeof timeout === 'number' ? timeout : 500;

        super(timeout);
        this.requireFiredStateOnTracking = false;
        this.omReady = new Promise((resolve, reject) => {
            CBS.Promises.Tracking.onLoad(() => {
                resolve();
            });
        });
    }

    async trackState(params) {
        try {
            await this.omReady;
            om.trackState(params);
            this.trackStateFired = true;
            this.trackQueuedActions();
        } catch (e) {
            this.onTrackingFail(e);
        }
    }

    trackCancelSubscription() {
        this.trackAction(actionName.CANCEL_SUB, {
            pageType: actionName.ACCOUNT_LANDING_PAGE,
        });
    }

    trackSelectDevice(deviceName) {
        if (typeof deviceName === 'string') {
            deviceName = deviceName.replace(/\s+/, '');

            this.trackAction(actionName.SELECT_DEVICE + deviceName);
        }
    }

    trackParentalSwitchOff() {
        this.trackAction(actionName.PARENTAL_CONTROLS_OFF, {
            [paramKey.PARENTAL_SWITCH_OFF]: '1',
        });
    }

    trackParentalSwitchCancel(pinFlag) {
        this.trackAction(actionName.PARENTAL_CONTROLS_CANCEL, {
            [paramKey.PARENTAL_SWITCH_ON]: pinFlag ? '1' : '0',
        });
    }

    trackParentalSwitchCancel(pinFlag) {
        this.trackAction(actionName.PARENTAL_CONTROLS_CANCEL, {
            [paramKey.PARENTAL_SWITCH_ON]: pinFlag ? '1' : '0',
        });
    }

    trackParentalSwitchCancel(pinFlag) {
        this.trackAction(actionName.PARENTAL_CONTROLS_CANCEL, {
            [paramKey.PARENTAL_SWITCH_ON]: pinFlag ? '1' : '0',
        });
    }

    trackParentalSwitchOn(rating, lockLiveTV) {
        this.trackAction(actionName.PARENTAL_CONTROLS_ON, {
            [paramKey.PARENTAL_SWITCH_ON]: '1',
            [paramKey.PARENTAL_RATING]: rating,
            [paramKey.PARENTAL_LOCK_LIVE_TV]: lockLiveTV ? '1' : '0',
        });
    }

    trackingParamPromoOffer(promoHeading = '') {
        return promoHeading
            .replace(/Wait!|!/g, '')
            .trim()
            .split(' ')
            .join('_');
    }

    /**
     * @param promoHeading
     */
    trackPromoOfferApplied(promoHeading = '') {
        this.trackState({
            pageType: actionName.ACCOUNT_LANDING_AGE,
            promoOffer: this.trackingParamPromoOffer(promoHeading),
            promoCancelOfferApplied: 1,
        });

        this.trackState({
            pageType: actionName.ACCOUNT_LANDING_AGE,
            promoOffer: null,
            promoCancelOfferApplied: null,
        });
    }

    trackPromoOfferDeclined(promoHeading = '') {
        let cancelTrackingObj = {
            promoOffer: this.trackingParamPromoOffer(promoHeading),
        };

        this.trackAction(actionName.DECLINE_OFFER_MODAL, cancelTrackingObj);

        sessionStorage.setItem('cancelTrackingObj', JSON.stringify(cancelTrackingObj));

        this.trackAction(actionName.DECLINE_OFFER_MODAL, {
            promoCancelOffer: cancelTrackingObj.promoOffer,
        });
    }

    trackPromoOfferDisplayed(promoHeading = '') {
        this.trackState({
            pageType: actionName.CANCEL_OFFER_MODAL,
            promoOffer: this.trackingParamPromoOffer(promoHeading),
        });
    }

    trackPromoOfferExited(promoHeading = '') {
        this.trackAction(actionName.CANCEL_EXIT, {
            promoOffer: this.trackingParamPromoOffer(promoHeading),
        });
    }

    trackPromoOfferRedeemed(promoHeading = '') {
        this.trackAction(actionName.REEDEM_NOW, {
            promoOffer: this.trackingParamPromoOffer(promoHeading),
        });
    }

    trackContinuousPlaySwitchState(action, switchState) {
        this.trackAction(action, {
            autoPlayEnabled: switchState,
        });
    }
}

AccountPageTrackingService.actionName = actionName;

export default AccountPageTrackingService;
