import { AA_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

export default {
    get(tags, userType, http, overrideRegion, overrideLocale) {
        return new Promise(function (resolve, reject) {
            http.doGet(`${AA_XHR_BASE_PATH}pa/`, {
                tags: tags.join(','),
                ut: userType,
                or: overrideRegion,
                ol: overrideLocale,
            })
                .then((res) => {
                    if (res.success && res.data.result) {
                        resolve(res.data.result);
                    } else {
                        // console.log(res)
                        reject(res.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getSkipStepNotification(partnerCode, http) {
        return new Promise(function (resolve, reject) {
            const xhrFetchUrl = `${AA_XHR_BASE_PATH}skip-step-notification-message/${partnerCode}/`;

            http.doGet(xhrFetchUrl)
                .then((res) => {
                    if (res.success && res.data) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
