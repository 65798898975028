<template>
    <div class="wrapper">
        <div class="padded-container">
            <div
                data-ci="summary-page"
                class="aa-layout payment-layout summary-page"
                :class="{ 'summary-page--signup-flow': isExSubscriber }"
                ref="paymentForm">
                <span v-if="!isExSubscriber" class="icon-fill-rounded__check"></span>
                <h3 class="summary-page__title">
                    {{ getTitle }}
                </h3>
                <p v-if="pageAttr.explainer_description_subtitle" class="summary-page__subtitle">
                    {{ pageAttr.explainer_description_subtitle }}
                </p>
                <div class="payment-layout__summary summary-page__details">
                    <OrderSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @trialEndDateFormatted="updateTrialEndDate"
                        ref="orderSummary"
                        :recurly="recurly"
                        :formConfig="orderSummaryConfig"
                        v-if="!enhancedPaymentSummaryEnabled"></OrderSummary>
                    <BillingAgreementSummary
                        @found-price="updatePrice"
                        :coupon="coupon"
                        :couponCheckPending="couponCheckPending"
                        :couponDisclaimer="couponDisclaimerCopy"
                        @applyCoupon="applyCoupon"
                        @updateOrder="updateOrder"
                        @trialEndDateFormatted="updateTrialEndDate"
                        ref="orderSummary"
                        :recurly="recurly"
                        :formConfig="orderSummaryConfig"
                        :promo="this.appliedCouponCode"
                        :pricing="pricing"
                        :pageAttr="pageAttr"
                        :nextNonZeroBillingPrice="nextNonZeroBillingPrice"
                        :couponDiscountBillingDate="couponDiscountBillingDate"
                        v-if="enhancedPaymentSummaryEnabled"></BillingAgreementSummary>
                    <BillingPaymentMethod
                        :pageAttr="pageAttr"
                        @is-payment-method-form-edit="
                            setPaymentMethodFormEnabled
                        "></BillingPaymentMethod>
                    <SummaryUpgradeDowngrade :pageAttr="pageAttr" />
                    <p v-html="getAgreementCopy()" class="payment-layout__summary__agreement"></p>
                    <input type="hidden" name="recurly-token" data-recurly="token" />

                    <FormButton
                        :button-config="submitConfig"
                        :button-state="submitButtonState"
                        ref="ctaSwitchPlan"
                        @click="subscribeOrUpdate"></FormButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    import couponsMixin from 'aa/vue/plugins/couponsMixin';
    import checkoutMixin from 'aa/vue/plugins/checkoutMixin';
    import switchPlanMixin from 'aa/vue/components/multisubplanpicker/switchPlanMixin';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import currentSubscriptionMixin from 'aa/vue/components/account/currentSubscriptionMixin';

    import { OrderSummaryConfig } from 'aa/vue/FormConfig';
    import BillingAgreementSummary from 'aa/vue/components/BillingAgreementSummary';
    import SummaryUpgradeDowngrade from 'aa/vue/components/SummaryUpgradeDowngrade';
    import OrderSummary from 'aa/vue/components/OrderSummary';
    import BillingPaymentMethod from 'aa/vue/components/BillingPaymentMethod';
    import FormButton from 'atoms/FormButton';
    import { ButtonState, default as ButtonConfig } from 'models/ButtonConfig';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import { MULTI_SUB_PLAN_PICKER_ENABLED } from 'helpers/featureConstants';

    export default {
        name: 'Summary',
        mixins: [
            couponsMixin,
            pricePrettyPrint,
            checkoutMixin,
            commonMixins,
            switchPlanMixin,
            currentSubscriptionMixin,
        ],
        components: {
            BillingAgreementSummary,
            BillingPaymentMethod,
            FormButton,
            SummaryUpgradeDowngrade,
            OrderSummary,
        },
        props: {
            isFromDirect: {
                type: Boolean,
                default: false,
            },
            handleProcessedPayment: {
                type: Function,
                default: () => {},
            },
            handleProcessedPaymentError: {
                type: Function,
                default: () => {},
            },
        },
        data: function () {
            return {
                payPalToken: null,
                paymentRequestData: {},
                bodyScrollPos: 0,
                orderSummaryConfig: OrderSummaryConfig,
                couponDiscountBillingDate: null,
                recaptchaAction: 'FORM_SWITCH_PLAN',
                paymentMethodFormEnabled: false,
            };
        },
        computed: {
            ...mapState(['autoPromoChecked']),
            ...mapGetters('plan', ['getSelectedPlan']),
            ...mapGetters('user', ['isExSubscriber']),
            submitConfig() {
                const displayText =
                    this.pageAttr?.billing_summary_cta_form_submit ?? this.$getLocale('buy_now');
                return {
                    styleClass: [
                        'button',
                        this.plan && !this.paymentMethodFormEnabled ? 'primary' : 'disabled',
                    ],
                    displayText,
                    event:
                        this.plan && !this.paymentMethodFormEnabled
                            ? this.subscribeOrUpdate
                            : undefined,
                    isDisabled: (!this.plan && this.paymentMethodFormEnabled) || this.isProcessing,
                };
            },
            submitButtonState() {
                if (this.isProcessing) {
                    return ButtonState.Disabled;
                }

                return ButtonState.Enabled;
            },
            subscribeOrUpdate() {
                return this.isExSubscriber ? this.submitExSubscriberPayment : this.submitOnClicked;
            },
            getTitle() {
                return this.isExSubscriber
                    ? this.pageAttr.explainer_description
                    : this.pageAttr.switch_plan_summary_header;
            },
            currentPlan() {
                if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                    return this.$store.getters['plan/getSelectedPlan'];
                } else {
                    return this.$store.getters['plan/getCurrentPlan'];
                }
            },
        },
        created() {
            if (!this.plan) {
                return;
            }

            const trackingParams = {
                productPricingPlan: this.plan.planType,
                pickPlanType: this.plan.planTier,
                pickPlanSku: this.plan.recurlyCode,
                productCurrentSku: this?.userProduct?.product_code ?? this.currentPlan.recurlyCode,
                productNewSku: this.plan.recurlyCode,
                ctaText: this.$getLocale('buy_now'),
                pageType: this.isFromDirect
                    ? 'switchplan_directLink_payment'
                    : 'svod_planchangeconfirmation',
                pageUrl: window.location.href,
            };

            if (this.isFromDirect) {
                trackingParams.purchaseProduct = this.plan.recurlyCode;
                trackingParams.purchaseProductName = this.plan.planTitle;
                trackingParams.purchasePrice = this.plan.rawPrice;
                trackingParams.productOfferPeriod = this.plan?.trialString ?? '';
                trackingParams.purchaseQuantity = '1';
                trackingParams.purchasePromoCode = this.appliedCouponCode;
            }

            const trackingServiceInstance = this.$trackingService ?? this.trackingService;

            trackingServiceInstance.trackState(trackingParams);
        },
        methods: {
            setPaymentMethodFormEnabled(event) {
                this.paymentMethodFormEnabled = event;
            },
        },
        watch: {
            // If there is an auto-applying promo that was checked, but not valid (no recurlyInfo),
            // Attempt to handle any 100% off promos that may be on the user's current subscription
            autoPromoChecked(newVal) {
                if (newVal === true && this.coupon?.recurlyInfo) {
                    let isUpgradeAnd100PercentOffPromo =
                        this.plan?.switchDirection === 'upgrade' &&
                        this.coupon.recurlyInfo?.discount?.type === 'percent' &&
                        this.coupon.recurlyInfo?.discount?.rate === 1;

                    if (isUpgradeAnd100PercentOffPromo) {
                        const { temporal_unit, temporal_amount } = this.coupon.recurlyInfo;
                        this.calcFullCouponBillingDate(this.coupon, temporal_amount, temporal_unit);
                    }
                }
            },
        },
        mounted() {
            if (this.$route.query?.notification) {
                this.openNotification(
                    new NotificationBarModel({
                        success: true,
                        autoClose: false,
                        message: this.$getLocale(this.$route.query.notification),
                    }),
                );
            }
        },
    };
</script>
