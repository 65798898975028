<!-- // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
<template>
    <div class="intl-view-ab-test">
        <div class="multi-sub-plan__flex" data-ci="tier-container">
            <v-app>
                <SlideGroup v-model="active" center-active>
                    <v-slide-item
                        v-for="(plan, idx) in plans"
                        :key="idx"
                        v-slot="{ active, toggle }">
                        <AbTestIntlLikeMultiSubBox
                            class="-tier"
                            ref="planWrapper"
                            :idx="idx"
                            :plan="plan"
                            :is-active="active"
                            :total-plans="plans.length"
                            :selection-made="selectionMade"
                            data-ci="tier-content"
                            @click="toggle"
                            @planSelected="setSelectedPlan"
                            @setFeaturesList="setFeaturesList"></AbTestIntlLikeMultiSubBox>
                    </v-slide-item>
                </SlideGroup>
            </v-app>
        </div>
        <div class="multi-sub-plan__active">
            <div class="plan-box__data">
                <FeatureList :plan="getHighlightedPlan" :features="featureList" />
            </div>
            <div class="multi-sub-plan__proceed-button -gradient">
                <ButtonCta :buttonConfig="getButtonConfig" />
            </div>
        </div>
    </div>
</template>

<script>
    import FeatureList from 'aa/vue/atoms/FeatureList';
    import PillComponent from 'aa/vue/components/PillComponent';
    import { mapState, mapGetters } from 'vuex';
    import ButtonCta from 'atoms/ButtonCta';
    import SlideGroup from 'aa/vue/components/multisubplanpicker/parts/SlideGroup';
    import VSlideItem from 'vuetify/lib/components/VSlideGroup/VSlideItem';
    import AbTestIntlLikeMultiSubBox from 'aa/vue/components/AbTestIntlLikeMultiSubBox';
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';

    export default {
        name: 'AbTestIntlLikePlans',
        mixins: [AbTestPlanTilesAboveFoldMixin],
        props: {
            plans: {
                type: Array,
                required: true,
            },
        },
        data: function () {
            return {
                featureList: [],
                selectionMade: false,
                buttonDisabledState: true,
            };
        },
        mounted() {
            if (this.highlightedPlanIdx !== -1) {
                // activate cta button
                this.buttonDisabledState = false;
            }
        },
        created() {
            // -1 value means its default, and no one plan yet selected, u can check below url for better understanding
            // https://github.com/cbsi-cbscom/web-allaccess/blob/main/src/js/vue/stores/modules/VariantStore.js#L56
            if (this.highlightedPlanIdx === -1) {
                this.setSelectedPlan({
                    idx: 0,
                    selectionMade: true,
                });
            }
        },
        methods: {
            setFeaturesList(featureList) {
                this.featureList = featureList;
            },
            handlePlanSelectedProxy() {
                if (this.highlightedPlanIdx >= 0) {
                    const plan = this.plans[this.highlightedPlanIdx];
                    this.$emit('planSelected', plan);
                }
            },
            setSelectedPlan(data) {
                const { idx, selectionMade } = data;
                this.active = idx;
                this.selectionMade = selectionMade;
                // activate cta button
                this.buttonDisabledState = false;
            },
            getButtonStyles() {
                let buttonClassList = ['ab-test-cta'];
                if (this.buttonDisabledState) {
                    buttonClassList.push('disabled');
                }
                return buttonClassList.join(' ');
            },
        },
        computed: {
            ...mapGetters({
                getNextStep: 'flow/getNextStep',
            }),
            getHighlightedPlan() {
                return this.highlightedPlanIdx === -1
                    ? this.plans[0]
                    : this.plans[this.highlightedPlanIdx];
            },

            getButtonConfig() {
                return Object.assign(
                    {},
                    {
                        displayText: 'Select Plan',
                        styleClass: this.getButtonStyles(),
                        tabIndex: 0,
                        event: () => {
                            this.handlePlanSelectedProxy();
                        },
                    },
                );
            },
            active: {
                get() {
                    return this.highlightedPlanIdx;
                },
                set(index) {
                    this.$store.commit('variant/setHighlightedPlanIdx', index);
                },
            },
        },
        components: {
            ButtonCta,
            PillComponent,
            AbTestIntlLikeMultiSubBox,
            FeatureList,
            SlideGroup,
            VSlideItem,
        },
    };
</script>
<!-- // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
