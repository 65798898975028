import { catanAddOnCode } from 'aa/vue/constants/aaBundle';

export const AARP_ENABLED = 'aarp_enabled';
export const AA_DYNAMIC_AGREEMENT_COPY = 'aa_dynamic_agreement_copy';
export const AA_PAYPAL = 'aa_paypal';
export const AA_PURCHASE_REVIEW_SCREEN = 'aa_purchase_review_screen';
// START feature/PPIWEBM-1594
export const AB_TEST_BEST_PLAN_RECOMMENDED = 'exp_web_intl_best_plan_recommended_ca_mx_br';
export const AB_TEST_BEST_PLAN_RECOMMENDED_WITH_SAVE_STRING =
    'exp_web_intl_best_plan_recommended_uk_it_gsa';
// END feature/PPIWEBM-1594
export const AGE_GATE = 'age_gate_enabled';
export const ANNUAL_UPSELL_PAYMENT = 'annual_upsell_on_payment';
export const AUTHSUITE_ENABLED = 'authsuite_enabled';
export const CAMPAIGN_SERVICE_ENABLED = 'campaign_service_enabled';
export const CONTINUE_FREE_TRIAL = 'continue_free_trial';
export const COLD_START_PREMIUM_LOCKS_ENABLED = 'cold_start_premium_locks_enabled';
export const CONTINUOUS_PLAY = 'continuous_play';
export const DIRECT_LINK = 'direct_link';
export const DISPLAY_RETENTION_OFFER = 'display_retention_offer';
export const EMAIL_VERIFICATION_ENABLED = 'email_verification_enabled';
export const ENHANCED_KIDS_PRIVACY_ENABLED = 'enhanced_kids_privacy_enabled';
export const ENHANCED_PAYMENT_SUMMARY_ENABLED = 'enhanced_payment_summary_enabled';
export const FORCE_ANNUAL_GIFT_CARD = 'force_annual_gift_card';
export const GIFT_SHIPPING_INFO_ENABLED = 'gift_shipping_info_enabled';
export const MFE_ENABLED = 'mfe_enabled';
export const MFE_USERPROFILES_ENABLED = 'mfe_userprofiles_enabled';
export const MULTI_SUB_PLAN_PICKER_ENABLED = 'multi_sub_plan_picker_enabled';
export const MVPD_SIGN_IN_HTML5_ENABLED = 'mvpd_sign_in_html5_enabled';
export const PAYMENT_METHOD_EDIT_SP = 'paymentmethodedit_sp';
export const PAYMENT_SUMMARY_CHANGE_LINK_ENABLED = 'payment_summary_change_link_enabled';
export const PAYPAL_EDIT_PAYMENT_ENABLED = 'paypal_edit_payment_enabled';
export const PICK_A_PLAN_COPY_FLEXIBILITY_ENABLED = 'pick_a_plan_copy_flexibility_enabled';
export const PLANPICKER_COMPLIANCE_ENABLED = 'planpicker_compliance_enabled';
export const PRICE_WITH_ASTERISK = 'PRICE_WITH_ASTERISK';
export const REDFAST_ENABLED = 'redfast_enabled';
export const REDFAST_PREMIUM_UPSELL_ENABLED = 'redfast_premium_upsell_enabled';
export const RESELLER_DELTA_AUTO_LOGIN = 'reseller_delta_auto_login';
export const RESELLER_DELTA_DEPEVIEW = 'reseller_delta_deepview';
export const RESELLER_DELTA_JOURNEY = 'reseller_delta_journey';
export const RESELLER_DELTA_JOURNEY_SIGN_IN = 'reseller_delta_journey_sign_in';
export const RESELLER_DELTA_JOURNEY_SIGN_UP = 'reseller_delta_journey_sign_up';
export const SCREENTIME_LIMIT_ENABLED = 'screentime_limit_enabled';
export const SHOWTIME_BANNER_ENABLED = 'walmartplus_success_showtime_banner_enabled';
export const SHOWTIME_INTEGRATION_ENABLED = 'showtime_integration_enabled';
export const SHOW_PICKER = 'show_picker';
export const SKIP_EXPLAINER_STEPS = 'skip_explainer_steps';
export const PLAN_HEADER_COPY_CHANGE = 'plan_header_copy_change';
export const SWITCH_PLAN_ENABLED = 'switch_plan_enabled';
export const SWITCH_PROFILE_PIN_ENABLED = 'switch_profile_pin_enabled';
export const TOKEN_EXPIRED = 'Unable to validate token reason: Token is not valid';
export const USER_PROFILES = 'user_profiles';
export const WAIVE_COOLING_RIGHTS_ENABLED = 'waive_cooling_rights_enabled';
export const WALMART_PLUS_CONSENT = 'walmart_plus_consent';
export const WEB_RENDEZVOUS_MVPD_ENABLED = 'web_rendezvous_mvpd_enabled';
export const WEB_SIGN_IN_MVPD_ENABLED = 'web_sign_in_mvpd_enabled';
export const WEB_RENDEZVOUS_SINGLE_URL_ENABLED = 'web_rendezvous_single_url_enabled';
export const GIFTCARD_TDS = 'giftcard_tds';
// START feature/PPIWEBM-2028
export const AB_TEST_ANNUAL_PLAN_HIGHLIGHT = 'web_annual_plan_highlight';
export const AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ALL = 'emphasis_icon_and_green_pill';
export const AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ICON_AND_GREEN_PILL = 'icon_and_green_pill';
export const AB_TEST_ANNUAL_PLAN_HIGHLIGHT_GREEN_PILL = 'green_pill';
// END feature/PPIWEBM-2028
export const CANCELLATION_SURVEY_RANDOMIZED_ENABLED = 'cancellation_survey_randomized_enabled';

export const FeatureKeys = {
    AARP_ENABLED: AARP_ENABLED,
    AA_DYNAMIC_AGREEMENT_COPY: AA_DYNAMIC_AGREEMENT_COPY,
    AA_PAYPAL: AA_PAYPAL,
    AA_PURCHASE_REVIEW_SCREEN: AA_PURCHASE_REVIEW_SCREEN,
    CAMPAIGN_SERVICE_ENABLED,
    COLD_START_PREMIUM_LOCKS_ENABLED: COLD_START_PREMIUM_LOCKS_ENABLED,
    CONTINUOUS_PLAY: CONTINUOUS_PLAY,
    EMAIL_VERIFICATION_ENABLED: EMAIL_VERIFICATION_ENABLED,
    ENHANCED_KIDS_PRIVACY_ENABLED: ENHANCED_KIDS_PRIVACY_ENABLED,
    ENHANCED_PAYMENT_SUMMARY_ENABLED: ENHANCED_PAYMENT_SUMMARY_ENABLED,
    FORCE_ANNUAL_GIFT_CARD: FORCE_ANNUAL_GIFT_CARD,
    GIFT_SHIPPING_INFO_ENABLED: GIFT_SHIPPING_INFO_ENABLED,
    MFE_ENABLED: MFE_ENABLED,
    MFE_USERPROFILES_ENABLED: MFE_USERPROFILES_ENABLED,
    MULTIPLE_ENTITLEMENTS_ENABLED: 'multiple_entitlements_enabled',
    MULTI_SUB_PLAN_PICKER_ENABLED: MULTI_SUB_PLAN_PICKER_ENABLED,
    MVPD_SIGN_IN_HTML5_ENABLED: MVPD_SIGN_IN_HTML5_ENABLED,
    PAYMENT_METHOD_EDIT_SP: PAYMENT_METHOD_EDIT_SP,
    PAYMENT_SUMMARY_CHANGE_LINK_ENABLED: PAYMENT_SUMMARY_CHANGE_LINK_ENABLED,
    PAYPAL_EDIT_PAYMENT_ENABLED: PAYPAL_EDIT_PAYMENT_ENABLED,
    PLANPICKER_COMPLIANCE_ENABLED: PLANPICKER_COMPLIANCE_ENABLED,
    PRICE_WITH_ASTERISK: PRICE_WITH_ASTERISK,
    REDFAST_ENABLED: REDFAST_ENABLED,
    REDFAST_PREMIUM_UPSELL_ENABLED: REDFAST_PREMIUM_UPSELL_ENABLED,
    SCREENTIME_LIMIT_ENABLED: SCREENTIME_LIMIT_ENABLED,
    SHOWTIME_INTEGRATION_ENABLED: SHOWTIME_INTEGRATION_ENABLED,
    SHOW_PICKER: SHOW_PICKER,
    SWITCH_PLAN_ENABLED: SWITCH_PLAN_ENABLED,
    SWITCH_PROFILE_PIN_ENABLED: SWITCH_PROFILE_PIN_ENABLED,
    TOKEN_EXPIRED: TOKEN_EXPIRED,
    USER_PROFILES: USER_PROFILES,
    WEB_RENDEZVOUS_MVPD_ENABLED: WEB_RENDEZVOUS_MVPD_ENABLED,
    WEB_RENDEZVOUS_SINGLE_URL_ENABLED: WEB_RENDEZVOUS_SINGLE_URL_ENABLED,
    WEB_SIGN_IN_MVPD_ENABLED: WEB_SIGN_IN_MVPD_ENABLED,
    GIFTCARD_TDS,
};

export default class Feature {
    static enabled(key) {
        return CBS.Registry.Features?.[key];
    }

    static multipleEntitlementsEnabled() {
        return this.enabled(FeatureKeys.MULTIPLE_ENTITLEMENTS_ENABLED);
    }

    /**
     * Checks if the partner bundle feature is enabled.
     * @returns {boolean} True if the partner bundle feature is enabled, false otherwise.
     */
    static partnerBundleEnabled() {
        return this.enabled(`${catanAddOnCode().toLowerCase()}_bundle_enabled`);
    }

    /**
     * Checks if the web rendezvous mvpd feature is enabled.
     *
     * @returns {boolean} True if web rendezvous mvpd feature is enabled, false otherwise.
     */
    static webRendezvousMvpdEnabled() {
        return this.enabled(FeatureKeys.WEB_RENDEZVOUS_MVPD_ENABLED);
    }

    static mvpdSignInHtml5Enabled() {
        return this.enabled(FeatureKeys.MVPD_SIGN_IN_HTML5_ENABLED);
    }
}
