import { FLOW_TYPE } from 'models/FlowModel';
import Feature from 'aa/helpers/featureConstants';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('flow', {
            flow: (state) => state.config,
        }),
    },
    methods: {
        getTrackingActivationCode: function (pageType, pageUrl, code) {
            return {
                pageType: pageType,
                pageUrl: pageUrl,
                ...this.$store.getters['flow/getTrackingActivationInfo'],
                ...(code ? { activationShortCode: code } : {}),
            };
        },
        isRendezvousMvpdSignIn: function () {
            return (
                Feature.mvpdSignInHtml5Enabled() &&
                this.flow?.type === FLOW_TYPE.AGNOSTIC_RENDEZVOUS
            );
        },
    },
};
