import { PageAttribute } from 'aa/vue/components/account/PageAttribute';
import AccountPageTrackingService from 'aa/services/AccountPageTrackingService';
import NotificationMixin from 'aa/vue/plugins/NotificationMixin';
import modalMixin from '../modalMixin';
import syncdataMixin from './syncdataMixin';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            token: this.$store ? this.$store.getters.getServerDataByKey('authToken') : null,
            trackActionName: AccountPageTrackingService.actionName,
        };
    },
    mixins: [NotificationMixin, modalMixin, syncdataMixin],
    filters: {
        datarole(value) {
            if (value && value.length > 0) {
                return value.toLowerCase().replace(/\s/g, '-');
            }

            return '';
        },
    },
    computed: {
        user() {
            return this.$store ? this.$store.state.user : null;
        },
        pageAttribute() {
            let fromStore = this.$store
                ? this.$store.state[this.componentStoreName].pageAttributes
                : null;
            return new PageAttribute(
                fromStore.hasOwnProperty(this.$options.name) ? fromStore[this.$options.name] : null,
            );
        },
        componentStoreName() {
            return this.overrideComponentStoreName || this.$route.name;
        },
        cmsImageBasePath() {
            if (
                typeof this.$store.state.serverData !== 'undefined' &&
                typeof this.$store.state.serverData.cmsImageHost !== 'undefined'
            )
                return this.$store.state.serverData.cmsImageHost + '/base/';
            else if (
                typeof this.$root.serverData !== 'undefined' &&
                typeof this.$root.serverData.cmsImageHost !== 'undefined'
            )
                return this.$root.serverData.cmsImageHost + '/base/';

            return null;
        },
        ...mapGetters({
            recaptchaFromActionEnabled: 'featureFlags/recaptchaFromActionEnabled',
        }),
    },
    methods: {
        /**
         * Get the switch state value from local storage.
         *
         * @returns {boolean}
         */
        getSwitchStateFromLocalStorage() {
            const localStorageKeyPrefix = 'continuous_play_';
            const userProfileId = CBS.Registry?.user?.profile_id;
            let switchState = localStorage.getItem(localStorageKeyPrefix.concat(userProfileId));

            // return true if localStorage has a (string) true value, or not set at all (product requirement)
            return switchState === 'true' || switchState === null;
        },
        buildHttpData(rawData) {
            let result = {
                tk_trp: this.token,
            };

            Object.assign(result, rawData);

            if (
                this.hasOwnProperty('recaptchaAction') &&
                this.recaptchaFromActionEnabled(this.recaptchaAction)
            ) {
                result.recaptchaAction = this.recaptchaAction;
            }

            return result;
        },
        closeModal() {
            this.$store.dispatch('modal/setIsShown', false);
        },
        matchesShowtimeSku(productCode) {
            let regex = new RegExp('(PPLUS_LCP|AA_CF)_SHO_(TRIAL_)?(MONTHLY|ANNUAL)');

            return regex.test(productCode);
        },
    },
};
