// START AB TEST CBSENTGRTH-1638 Implement show picker a/b test
const SHOWPICKER_ALIGNMENT_TEST = 'show_picker_ui_update_web';
const REQUIRED_SHOWSPICKER_VARIANT = 'not-required';
const CONTROL_VARIANT = 'control';
// END AB TEST CBSENTGRTH-1638 Implement show picker a/b test

import { TestVariantBestValue } from 'aa/vue/abTestRelated/AbTestRelated';

import CoreVariantStore from 'apps/abTests/stores/CoreVariantStore';
import ShowPickerVariantStore from 'apps/abTests/stores/ShowPickerVariantStore';
import Domain from 'helpers/Domain';

// START TEST PLOCTOPLUS-2036 plan tiles above fold
const MODULE_KEY_PLAN_TILES_ABOVE_FOLD = 'WebPickPlanTilesAboveFold';
const MODULE_VARIANT_KEY_NATIVE_APP_VIEW = 'VARIANT_NATIVE_APP_VIEW';
const MODULE_VARIANT_KEY_INTL_WEB_VIEW = 'VARIANT_INTL_WEB_VIEW';
const EXPERIMENT_NAME_PLAN_TILES_ABOVE_FOLD = 'web_pick_a_plan_plan_tiles_above_fold';
const VARIANT_NATIVE_APP_VIEW = 'nativeappview';
const VARIANT_INTL_WEB_VIEW = 'intlwebview';
// END TEST PLOCTOPLUS-2036 plan tiles above fold

// START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
const WEB_PAP_ANNUAL_PLAN_EMPHASIS = 'PapAnnualPlanEmphasis';
const CENTER_ALIGNED_TOGGLE_GREEN_SAVINGS_PILL = 'VARIANT_CENTER_ALIGNED_TOGGLE_GREEN_SAVINGS_PILL';
const BILLING_CADENCE_MODAL = 'VARIANT_BILLING_CADENCE_MODAL';
// END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy

const MODULE_KEY_CONFIRM_EMAIL = 'EmailConfirmationField';
const VARIANT_EMAIL_FIELD = 'VARIANT_EMAIL_FIELD';
const VARIANT_EMAIL_FIELD_SINGLE_COLUMN = 'VARIANT_EMAIL_FIELD_SINGLE_COLUMN';

export default {
    namespaced: true,

    state: {
        ...CoreVariantStore.state,
        variant: {
            // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
            web_pick_a_plan_plan_tiles_above_fold: null,
            // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        },

        //START A/B TEST PLOCTOPLUS-513 : Auto-Fill Zip
        zipCode: '',
        //END A/B TEST PLOCTOPLUS-513 : Auto-Fill Zip

        // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        highlightedPlanIdx: -1,
        // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    },

    mutations: {
        ...CoreVariantStore.mutations,

        setVariant(state, variant) {
            state.variant = variant;
        },

        //START A/B TEST PLOCTOPLUS-513 : Auto-Fill Zip
        setZipCode(state, data) {
            state.zipCode = data;
        },
        //END A/B TEST PLOCTOPLUS-513 : Auto-Fill Zip

        // START AB TEST CBSENTGRTH-1638 Implement show picker a/b test
        setShowsPickerVariants: function (state, variants) {
            state.showsPickerVariants = variants;
        },
        // END AB TEST CBSENTGRTH-1638 Implement show picker a/b test

        // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        setHighlightedPlanIdx(state, idx) {
            state.highlightedPlanIdx = idx;
        },
        // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    },

    actions: {
        ...CoreVariantStore.actions,
        ...ShowPickerVariantStore.actions,

        setVariant({ commit }, variant) {
            commit('setVariant', variant);
        },

        // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        setHighlightedPlanIdx({ commit }, idx) {
            commit('setHighlightedPlanIdx', idx);
        },
        // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    },

    // access the state
    getters: {
        ...CoreVariantStore.getters,

        getVariant: (state) => {
            return state.variant;
        },

        // START A/B TEST PLGRWEB-6 : Pick-a-Plan Annual Copy Test
        getAnnualCopyTextVariant: (state) => {
            return state.variant.pick_a_plan_annual_copy_test;
        },
        // END A/B TEST PLGRWEB-6 : Pick-a-Plan Annual Copy Test

        // START TEST PLOCTOPLUS-2610: pap free trial cta ab test
        isPapFreeTrialCtaAbTest: (state) => {
            return (
                state?.variant?.web_pick_a_plan_cta_copy_test ===
                CBS.AbTestsConfigs?.XhrABTestsConstants
                    ?.VARIANT_WEB_PICK_A_PLAN_CTA_COPY_TEST_FREE_TRIAL
            );
        },
        // END TEST PLOCTOPLUS-2610: pap free trial cta ab test

        // START TEST PLOCTOPLUS-2873: email confirmation field ab test
        displayConfirmationEmailField: (state, getters) => {
            if (Domain.isDomestic()) {
                return (
                    getters.experimentIs(MODULE_KEY_CONFIRM_EMAIL, VARIANT_EMAIL_FIELD) ||
                    getters.experimentIs(
                        MODULE_KEY_CONFIRM_EMAIL,
                        VARIANT_EMAIL_FIELD_SINGLE_COLUMN,
                    )
                );
            } else {
                return false;
            }
        },
        // END TEST PLOCTOPLUS-2873: email confirmation field ab test

        // START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
        isSimplifiedPlansPOPI: (state, getters, rootState, rootGetters) => {
            if (CBS.AbTestsConfigs?.XhrABTestsConstants === undefined) {
                return false;
            }
            return (
                state?.variant?.web_simplified_plans_tiles_for_popi ===
                    CBS.AbTestsConfigs?.XhrABTestsConstants
                        ?.VARIANT_WEB_SIMPLIFIED_PLANS_TILES_POPI_PROPS ||
                state?.variant?.web_simplified_plans_tiles_for_popi ===
                    CBS.AbTestsConfigs?.XhrABTestsConstants
                        ?.VARIANT_WEB_SIMPLIFIED_PLANS_TILES_POPI_NOPROPS
            );
        },

        plansWithValueProps: (state) => {
            return (
                state?.variant?.web_simplified_plans_tiles_for_popi ===
                CBS.AbTestsConfigs?.XhrABTestsConstants
                    ?.VARIANT_WEB_SIMPLIFIED_PLANS_TILES_POPI_PROPS
            );
        },

        plansWithNoProps: (state) => {
            return (
                state?.variant?.web_simplified_plans_tiles_for_popi ===
                CBS.AbTestsConfigs?.XhrABTestsConstants
                    ?.VARIANT_WEB_SIMPLIFIED_PLANS_TILES_POPI_NOPROPS
            );
        },
        // END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI

        // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        isPlanTilesAboveFoldNativeAppView: (state, getters) => {
            return getters.experimentIs(
                MODULE_KEY_PLAN_TILES_ABOVE_FOLD,
                MODULE_VARIANT_KEY_NATIVE_APP_VIEW,
            );
        },
        isPlanTilesAboveFoldIntlLikeView: (state, getters) => {
            return getters.experimentIs(
                MODULE_KEY_PLAN_TILES_ABOVE_FOLD,
                MODULE_VARIANT_KEY_INTL_WEB_VIEW,
            );
        },
        // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold

        // START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
        isBillingCadenceModal: (state, getters) => {
            return getters.experimentIs(WEB_PAP_ANNUAL_PLAN_EMPHASIS, BILLING_CADENCE_MODAL);
        },
        // END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy

        // START TEST PLOCTOPLUS-1915: center align payment page
        isCenterAlignPaymentPage: (state, getters, rootState, rootGetters) => {
            return (
                state.variant.web_center_align_payment_page === 'modulesabove' ||
                state.variant.web_center_align_payment_page === 'modulesbelow'
            );
        },

        isCenterAlignPaymentPageModulesAbove: (state) => {
            return state.variant.web_center_align_payment_page === 'modulesabove';
        },
        // END TEST PLOCTOPLUS-1915: center align payment page

        // START AB Test PLOCTOPLUS-2033 - Green lock icon on payment
        isPaymentGreenLockIcon: (state) => {
            return state.variant.web__payment_lock_icon === 'greenlockicon';
        },
        isPaymentHypertextLink: (state) => {
            return state.variant.web__payment_lock_icon === 'hyperlinktext';
        },
        // END AB Test PLOCTOPLUS-2033 - Green lock icon on payment

        // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
        isIntlUpdatedExplainerSteps: (state) => {
            return state?.variant?.intl_web_skip_explainer_steps === 'updatedexplainersteps';
        },
        isIntlRemoveExplainerSteps: (state) => {
            return state?.variant?.intl_web_skip_explainer_steps === 'removeexplainersteps';
        },
        // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps

        //START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        isAnnualUpsellPaymentPageToggle: (state) => {
            return state?.variant?.web_annual_upsell_on_payment_page_revised_ui_ux === 'toggle';
        },
        isAnnualUpsellPaymentPageTwoPlanTiles: (state) => {
            return (
                state?.variant?.web_annual_upsell_on_payment_page_revised_ui_ux === 'two_plan_tiles'
            );
        },
        //END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

        // START TEST PLOCTOPLUS-2359: A/B Test: Center Align & Field Hierarchy on Create Account Page
        twoColumnFieldAlignmentEmailFirst: (state) => {
            return (
                state?.variant?.web_center_align_field_hierarchy_on_create_account_page ===
                'email_first'
            );
        },
        centerAlignFieldEmailAndPassFirst: (state) => {
            return (
                state?.variant?.web_center_align_field_hierarchy_on_create_account_page ===
                'center_align_email_first'
            );
        },
        // END TEST PLOCTOPLUS-2359: A/B Test: Center Align & Field Hierarchy on Create Account Page

        // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        plansWithShoPreSelected: (state) => {
            return state?.variant?.web_pap_preselect_pwithsho === 'pwithshopreselected';
        },
        plansWithShoPreSelectedOneCTA: (state) => {
            return state?.variant?.web_pap_preselect_pwithsho === 'pwithshopreselectedonecta';
        },
        // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

        // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        purchaseFunnelOrdering: (state) => {
            return state?.variant?.web_purchase_funnel_reorder === 'create_account_first';
        },
        // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
    },
};
